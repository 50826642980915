import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { createSwapOrder, validateQrCode } from "../graphql/mutations";
import { getMySwapOrderBySpName, queryOrderHistory } from "../graphql/queries";
import { setError } from "./errorSlice";
import React from "react";

import { confirmOpenDoor, cancelOrder } from "../graphql/mutations";

import { sub2SwapStateNotification } from "../graphql/subscriptions";
import { toast } from "react-toastify";
import NotificationContent from "../components/NotificationContent";
import { navigate } from "@reach/router";

export const createOrder = createAsyncThunk(
  "createOrder",
  async (
    {
      batteryCount,
      batteryType,
      unique_validation_hash_key,
      sharing_point_name
    },
    { dispatch }
  ) => {
    let { data } = await API.graphql(
      graphqlOperation(validateQrCode, { code: unique_validation_hash_key })
    );

    let validationResponse = data.validate_qr_code;
    if (!validationResponse.success) {
      toast.error(
        <NotificationContent
          iconName={"close"}
          message={"Order request failed"}
        />,
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false
        }
      );
      return;
    }
    let jwt = validationResponse.data.unique_validation_hash_key;
    let createSwapOrderResponse = await API.graphql(
      graphqlOperation(createSwapOrder, {
        battery_count: batteryCount,
        battery_type: batteryType,
        unique_validation_hash_key: jwt,
        sharing_point_name
      })
    );
    const { message, code, success } = createSwapOrderResponse.data.order;

    if (!success) {
      dispatch(setError({ message, statusCode: +code }));
      toast.error(
        <NotificationContent iconName={"close"} message={message} />,
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false
        }
      );

      return data;
    } else {
      navigate(`/swap/${jwt}`);
      return data;
    }
  }
);

export const fetchSwapOrderBySpName = createAsyncThunk(
  "fetchSwapOrderBySpName",
  async token => {
    let { data } = await API.graphql(
      graphqlOperation(getMySwapOrderBySpName, { token })
    );
    return data;
  }
);

export const subscribeToOrder = createAsyncThunk(
  "subscribeToOrder",
  async ({ sharing_point_name, user_name, unique_validation_hash_key }) => {
    if (sharing_point_name && user_name && unique_validation_hash_key) {
      let { data } = await API.graphql(
        graphqlOperation(sub2SwapStateNotification, {
          sharing_point_name,
          user_name,
          unique_validation_hash_key
        })
      ).subscribe({
        next: eventData => {
          const s = eventData.value.data;
        }
      });
      return data;
    }
  }
);

export const confirm_open_door = createAsyncThunk(
  "confirmOpenDoor",
  async token => {
    let { data } = await API.graphql(
      graphqlOperation(confirmOpenDoor, { token })
    );
    return data;
  }
);

export const cancel_order = createAsyncThunk("cancelOrder", async token => {
  let { data } = await API.graphql(
    graphqlOperation(cancelOrder, { token })
  );
  return data;
});

export const getOrderHistory = createAsyncThunk("getOrderHistory", async () => {
  let { data } = await API.graphql(graphqlOperation(queryOrderHistory));
  return data;
});

const swapOrderSlice = createSlice({
  name: "swapOrder",
  initialState: {
    swapOrder: {
      sharing_point_name: "",
      unique_validation_hash_key: "",
      battery_type: "",
      battery_count: 1
    },
    mySwapOrder: "",
    isLoadingFetchSwapOrder: false,
    isLoading: false,
    isLoadingConfirmOpenDoor: false,
    isLoadingForceCompletion: false,
    message: "",
    orderHistory: [],
    errors: ""
  },
  reducers: {
    setSharingpointName: (state, actions) => {
      state.swapOrder.sharing_point_name = actions.payload;
    },
    setUniqueValidationHashKey: (state, actions) => {
      state.swapOrder.unique_validation_hash_key = actions.payload;
    },
    setBatteryType: (state, actions) => {
      state.swapOrder.battery_type = actions.payload;
    },
    setBatteryCount: (state, actions) => {
      state.swapOrder.battery_count = actions.payload;
    },
    resetSwapOrder: (state, actions) => {
      console.log("resetSwapOrder---->")
      state.swapOrder.sharing_point_name = null;
      state.swapOrder.unique_validation_hash_key = null;
      state.swapOrder.battery_type = null;
      state.swapOrder.battery_count = 1;
      state.mySwapOrder = "" 
    },
  },
  extraReducers: {
    [createOrder.fulfilled]: (state, actions) => {
      state.isLoading = false;
    },
    [createOrder.rejected]: (state, actions) => {
      state.isLoading = false;
      state.message = "something went wrong. Please try again later.";
    },
    [createOrder.pending]: (state, actions) => {
      state.isLoading = true;
    },
    [fetchSwapOrderBySpName.pending]: (state, actions) => {
      state.isLoadingFetchSwapOrder = true;
    },
    [fetchSwapOrderBySpName.fulfilled]: (state, actions) => {
      if (actions.payload.order.code === "200") {
        state.mySwapOrder = actions.payload.order.data;
      } else {
        state.error = actions.payload.order.message;
        state.mySwapOrder = actions.payload.order.data;
      }
      state.isLoadingFetchSwapOrder = false;
    },
    [fetchSwapOrderBySpName.rejected]: (state, actions) => {
      state.isLoadingFetchSwapOrder = false;
    },
    [confirm_open_door.pending]: (state, actions) => {
      state.isLoadingConfirmOpenDoor = true;
    },
    [confirm_open_door.fulfilled]: (state, actions) => {
      state.isLoadingConfirmOpenDoor = false;
      state.mySwapOrder.swap_state = "OPEN";
    },
    [confirm_open_door.rejected]: (state, actions) => {
      state.isLoadingConfirmOpenDoor = false;
    },
    [sub2SwapStateNotification.fulfilled]: (state, actions) => {
      console.log(actions);
    },
    [sub2SwapStateNotification.rejected]: (state, actions) => {
      console.log(actions);
    },
    [getOrderHistory.pending]: (state, actions) => {
      state.isLoading = true;
    },
    [getOrderHistory.fulfilled]: (state, actions) => {
      state.isLoading = false;
      state.orderHistory = actions.payload.history.data || [];
    },
    [getOrderHistory.rejected]: (state, actions) => {
      state.isLoading = false;
    },
    [cancel_order.pending]: (state, actions) => {
      state.isLoadingForceCompletion = true;
    },
    [cancel_order.fulfilled]: (state, actions) => {
      state.isLoadingForceCompletion = false;
      state.mySwapOrder.swap_state = "COMPLETED";
    },
    [cancel_order.rejected]: (state, actions) => {
      state.isLoadingForceCompletion = false;
    }
  }
});

export const {
  setSharingpointName,
  setUniqueValidationHashKey,
  setBatteryCount,
  setBatteryType,
  resetSwapOrder
} = swapOrderSlice.actions;
export default swapOrderSlice.reducer;
