import React, { useState } from 'react'
import { Button, Label, Rating } from 'semantic-ui-react'
import "./ratingSection.css";

export default function RatingSection({ t, handelOnSendingRating, handelOnIgnoreRating, options }) {
  const [showOptions, setShowOptions] = useState(false)
  const [ableToSendRating, setAbleToSendRating] = useState(false)
  const [rating, setRating] = useState(0);
  const [active, setActive] = useState();
  const [selectedOption, setSelectedOption] = useState("");

  // console.log("options: ", options)

  const handleChangeOnRate = (e, { rating }) => {
    e.preventDefault()
    setRating(rating)
    if (rating < 5) {
      setShowOptions(true)
      setAbleToSendRating(false)
      setActive()
    }
    else {
      setShowOptions(false)
      setAbleToSendRating(true)
    }
  }
  const handleSelectOption = (option, key) => {
    setActive(key)
    setSelectedOption(option)
    setAbleToSendRating(true)
    // console.log("Selected: ", option)
  }
  return (
    <div style={{
      height: "550px",
      width: "950px",
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    }}>
      <div style={{ margin: "0 auto" }}>
        <Rating maxRating={5} defaultRating={rating} icon='star' size='massive'
          value={rating}
          style={{ fontSize: "136px" }}
          onRate={handleChangeOnRate}
        />
      </div>

      {options && showOptions ?
        <div style={{ display: "flex", flexWrap: "wrap", gap: "22px", justifyContent: "space-around" }}>
          {options.map((item, key) => (
            <Button
              key={key}
              className={active == key ? "active" : null}
              circular
              onClick={() => handleSelectOption(item, key)}
              style={{
                fontSize: 30,
                border: 0,
                color: "black",
                fontWeight: 400,
                padding: "20px 30px",
                margin: 0
              }}
              content={t(item)}
            />
          ))}
        </div>
        : null}

      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
        <Label style={{
          fontSize: 30,
          color: "black",
          border: 0,
          textDecoration: "underline"
        }}
          basic={true}
          onClick={handelOnIgnoreRating}
        >{t("No maybe later")}</Label>
        <Button
          style={{
            width: "350px",
            borderRadius: 50,
            backgroundColor: "#D8D8D8",
            fontSize: 30,
            color: "black",
            padding: 35,
            margin: 0
          }}
          className="send-button"
          disabled={!ableToSendRating}
          primary
          circular
          size="massive"
          content={t("Send")}
          onClick={() => handelOnSendingRating(rating, selectedOption)}
        />
      </div>

    </div>
  )
}
