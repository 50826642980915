import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setError } from "./errorSlice";
import { API, graphqlOperation } from "aws-amplify";
import {
  getSharingpointMapdataQuery,
  getSharingpointDetaildata
} from "../graphql/queries";

export const fetchSharingpoints = createAsyncThunk(
  "fetchSharingpoints",
  async (data, { dispatch }) => {
    const response = await API.graphql(
      graphqlOperation(getSharingpointMapdataQuery, {
        latitude: data.latitude,
        longitude: data.longitude,
        radius: data.radius
      })
    );
    if (+response.data.sharingpoints.code === 200) {
      if (
        response.data.sharingpoints.message !== process.env.REACT_APP_VERSION
      ) {
        window.location.reload();
      }
      return response.data.sharingpoints.data;
    }
  }
);

export const selectSharingpoint = createAsyncThunk(
  "selectSharingpoint",
  async (sharingpoint, { dispatch }) => {
    try {
      const detail = await fetchSharingpointDetail(sharingpoint);
      if (!detail.success) {
        dispatch(
          setError({ message: detail.message, statusCode: detail.statusCode })
        );
      }
      return detail;
    } catch (error) {
      throw error;
    }
  }
);

const fetchSharingpointDetail = async sharingpoint => {
  try {
    const { data, error } = await API.graphql(
      graphqlOperation(getSharingpointDetaildata, {
        sharing_point_name: sharingpoint.sharing_point_name
      })
    );

    if (+data.sharingpoint.code === 200) {
      return {
        success: true,
        ...sharingpoint,
        ...data.sharingpoint.data
      };
    } else {
      return {
        success: false,
        statusCode: +data.sharingpoint.code,
        message: data.sharingpoint.message
      };
    }
  } catch (error) {
    throw error;
  }
};

const sharingpointSlice = createSlice({
  name: "sharingpoints",
  initialState: {
    data: [],
    error: "",
    selectedSharingpoint: undefined,
    isLoading: false,
    success: false,
    selectSharingpointSuccess: null
  },
  reducers: {
    unselectSharingpoint: state => {
      state.selectedSharingpoint = undefined;
    }
  },
  extraReducers: {
    [fetchSharingpoints.pending]: (state, actions) => {
      state.isLoading = true;
    },
    [fetchSharingpoints.fulfilled]: (state, actions) => {
      state.data = actions.payload ? actions.payload : [];

      state.isLoading = false;
    },
    [fetchSharingpoints.rejected]: (state, actions) => {
      state.error = actions;
      state.isLoading = false;
    },
    [selectSharingpoint.fulfilled]: (state, actions) => {
      state.selectedSharingpoint = actions.payload;
      state.success = state.selectedSharingpoint.success;
      
    },
    [selectSharingpoint.rejected]: (state, actions) => {
      state.error = actions;
    }
  }
});

export const { unselectSharingpoint } = sharingpointSlice.actions;
export default sharingpointSlice.reducer;
