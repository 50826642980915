let awsmobile = {};

if (process.env.REACT_APP_MODE === "production") {
  awsmobile = {
    aws_appsync_graphqlEndpoint:
      "https://mjjlnaqdmjcmhe55gy7sta6lfa.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_user_pools_id: "eu-central-1_QbKugUzUR",
    aws_user_pools_web_client_id: "6hof405f01htgopi1kqdrlc30",
    FLASK_REST_API: "https://0wc3wec9se.execute-api.eu-central-1.amazonaws.com/rel_v1"

  };
} else if (process.env.REACT_APP_MODE === "beta") {
  awsmobile = {
    aws_appsync_graphqlEndpoint:
      "https://i4zanxgoyvhwbe24pczb6txdhq.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_user_pools_id: "eu-central-1_pO2DYmHFs",
    aws_user_pools_web_client_id: "725g7817o883jsbit0vlohrlgf",
    FLASK_REST_API: "https://xkwq519ksh.execute-api.eu-central-1.amazonaws.com/beta_v1"
  };
} else if (process.env.REACT_APP_MODE === "dev") {
  awsmobile = {
    aws_appsync_graphqlEndpoint:
      "https://2kgmdox2njd3tdg6i7xip2t46u.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_user_pools_id: "eu-central-1_OoM1aKxAJ",
    aws_user_pools_web_client_id: "7qaq5vv1o5mq25cob472n8vg5s",
    FLASK_REST_API: "https://r1xug2kf96.execute-api.eu-central-1.amazonaws.com/dev_v1"
    
  };
}else if (process.env.REACT_APP_MODE === "local") {
  awsmobile = {
    aws_appsync_graphqlEndpoint:
      "https://mjjlnaqdmjcmhe55gy7sta6lfa.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_user_pools_id: "eu-central-1_QbKugUzUR",
    aws_user_pools_web_client_id: "6hof405f01htgopi1kqdrlc30",
    // FLASK_REST_API: "https://r1xug2kf96.execute-api.eu-central-1.amazonaws.com/dev_v1"
    FLASK_REST_API: " http://127.0.0.1:5000"
  };
}else if (process.env.REACT_APP_MODE === "local-beta") {
  awsmobile = {
    aws_appsync_graphqlEndpoint:
      "https://i4zanxgoyvhwbe24pczb6txdhq.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_user_pools_id: "eu-central-1_pO2DYmHFs",
    aws_user_pools_web_client_id: "725g7817o883jsbit0vlohrlgf",
    FLASK_REST_API: " http://127.0.0.1:5000"
  };
}else if (process.env.REACT_APP_MODE === "staging-beta") {
  awsmobile = {
    aws_appsync_graphqlEndpoint:
      "https://i4zanxgoyvhwbe24pczb6txdhq.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_user_pools_id: "eu-central-1_pO2DYmHFs",
    aws_user_pools_web_client_id: "725g7817o883jsbit0vlohrlgf",
    FLASK_REST_API: "https://rxfflioav3.execute-api.eu-central-1.amazonaws.com/staging-beta",
  };
}

export default awsmobile;
  