import React, { useState, useRef } from 'react'
import { Segment, Button } from 'semantic-ui-react'
import "./index.css";
import BattCard from './BattCard';
import useTimeout from "../../shared/hooks/useTimeout"
import LogoutAlert from "../LogoutAlert"

export default function PublicInfoDisplay({ station }) {
  const [showLogoutAlert, setShowLogoutAlert] = useTimeout()
  const [active, setActive] = useState("1");
  const [selectedCharingModule, setSelectedCharingModule] = useState("0")
  const listModuleRef = useRef()

  const onSelectCharingModule = (box_number) => {
    setActive((box_number).toString())
    setSelectedCharingModule((box_number - 1).toString())
  }
  const onMoving = (action) => {
    var nextCmNumber = 0
    var scrollOffset = 0
    if (action === "up") {
      nextCmNumber = Number(selectedCharingModule) - 1
      scrollOffset = -20
    }
    if (action === "down") {
      nextCmNumber = Number(selectedCharingModule) + 1
      scrollOffset = 20
    }
    if (nextCmNumber >= 0 && nextCmNumber <= maxNumberOfCharingModules) {
      onSelectCharingModule(nextCmNumber + 1)
      listModuleRef.current.scrollTop += scrollOffset;
    }
  }
  // console.log(`setSelectedCharingModule: `, selectedCharingModule)
  const { modules } = station ? station : {};
  const maxNumberOfCharingModules = modules ? Object.keys(modules).length - 1 : 0
  return (
    <>
      {showLogoutAlert ? <LogoutAlert setShowLogoutAlert={setShowLogoutAlert} /> :
        (
          <Segment
            style={{
              borderRadius: 50,
              backgroundColor: "#ff5a00",
              position: "fixed",
              top: 120,
              left: 60,
              zIndex: 30,
              width: 1200,
              display: "flex",
              paddingRight: 0,
            }}
          >
            <div
              style={{
                borderRadius: 40,
                backgroundColor: "white",
                height: 530,
                width: 1050,
                display: "flex",
              }}
            >
              <div
                style={{
                  flexBasis: "30%",
                  minWidth: "320px",
                  borderRadius: 40
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    height: "100%",
                    overflow: "auto",
                  }}
                  ref={listModuleRef}
                >
                  {modules?.map(item => (
                    item.box_sub_type ?
                      <div
                        key={item.box_number}
                        id={item.box_number}
                        className={active === item.box_number ? "active" : null}
                        style={{
                          backgroundColor: "#ff5a00", color: "white", textAlign: "left",
                          textTransform: "capitalize", padding: "30px 0px 30px 25px",
                          borderRadius: "0 40px 40px 0", fontSize: "30px", fontWeight: "bolder"
                        }}
                        onClick={() => onSelectCharingModule(item.box_number)}
                      > {`${item.box_number} - ${item.box_sub_type.split(" ", 2).join(" ")}`}</div>
                      :
                      null)
                  )}

                </div>
              </div>
              {modules && modules[selectedCharingModule]?.structure ? (
                <div
                  style={{
                    flexBasis: "70%", display: "flex", justifyContent: "center",
                    margin: Object.keys(modules[selectedCharingModule].structure["0"]).length > 1 ? "30px 15px" : "120px 15px",
                  }}
                >
                  {Object.keys(modules[selectedCharingModule].structure).map((columnNumber, index) => (
                    <div key={index}
                      style={{
                        flexBasis: "100%",
                        display: "flex", flexDirection: "column", justifyContent: "space-around", borderLeft: index > 0 ? "solid" : ""
                      }}
                    >
                      {Object.keys(modules[selectedCharingModule].structure[columnNumber]).map((key, index) => {
                        var slotNumber = "slot_" + modules[selectedCharingModule].structure[columnNumber][key]
                        var slot_state = modules[selectedCharingModule].battery_state[slotNumber].slot_state
                        var is_charging = modules[selectedCharingModule].is_charging_batt[slotNumber].is_charging
                        var soc_value = modules[selectedCharingModule].state_of_charge[slotNumber].soc_value

                        if (Object.keys(modules[selectedCharingModule].structure).length > 3)
                          return <BattCard key={slotNumber} {...{ slotNumber, slot_state, is_charging, soc_value, index, displayAsSmall: true }} />
                        else
                          return <BattCard key={slotNumber} {...{ slotNumber, slot_state, is_charging, soc_value, index, displayAsSmall: false }} />
                      })}
                    </div>
                  ))}
                </div>
              ) :
                <div style={{
                  display: "flex", flexDirection: "column", justifyContent: "center",
                  fontSize: "50px", fontWeight: "bolder", color: "#ff5a00"
                }}>No available information!</div>
              }
            </div>
            <div
              style={{ height: 530, width: 130, display: "flex", flexDirection: "column", justifyContent: "flex-end", gap: "15px" }}
            >
              <Button
                icon={{ name: "arrow up", size: "huge" }}
                style={{
                  color: "#ff5a00",
                  backgroundColor: "white",
                  width: 110,
                  height: 110,
                  margin: "0 auto"
                }}
                circular
                disabled={selectedCharingModule === "0" ? true : false}
                onClick={() => onMoving("up")}
              ></Button>
              {/* <Icon
                name={"arrow alternate circle up"}
                style={{ color: "white", margin: 0 }}
                size="massive"
                disabled={selectedCharingModule === "0" ? true : false}
                onClick={() => onMoving("up")}
              /> */}
              {/* <Icon
                name={"arrow alternate circle down"}
                style={{ color: "white", margin: 0 }}
                size="massive"
                disabled={Number(selectedCharingModule) >= maxNumberOfCharingModules ? true : false}
                onClick={() => onMoving("down")}
              /> */}
              <Button
                icon={{ name: "arrow down", size: "huge" }}
                style={{
                  color: "#ff5a00",
                  backgroundColor: "white",
                  width: 110,
                  height: 110,
                  margin: "0 auto"
                }}
                circular
                disabled={Number(selectedCharingModule) >= maxNumberOfCharingModules ? true : false}
                onClick={() => onMoving("down")}
              ></Button>
            </div>
          </Segment>
        )
      }
    </>
  )
}
