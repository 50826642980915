import { combineReducers } from "redux";
import sharingpointReducer from "../sharingpointSlice";
import loginReducer from "../loginSlice";
import appStateReducer from "../appState";
import swapOrderReducer from "../swapOrderSlice";
import userReducer from "../userSlice";
import errorReduce from "../errorSlice";
import commandReducer from "../commandSlice";
import { servicesRatingApi } from "../services/servicesRating";


export default combineReducers({
  sharingpoints: sharingpointReducer,
  login: loginReducer,
  appState: appStateReducer,
  swapOrder: swapOrderReducer,
  user: userReducer,
  error: errorReduce,
  command: commandReducer,
  [servicesRatingApi.reducerPath]: servicesRatingApi.reducer
});
