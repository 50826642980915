import React, { useState, useEffect, useCallback } from "react";
import { Grid, GridColumn, Label, Icon, Button } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { navigate } from "@reach/router";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import NotificationContent from "../NotificationContent";
import awsconfig from "../../aws-exports/aws-exports-rel";
import {
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { loginSuccess } from "../../store/loginSlice";
import jwtDecode from "jwt-decode";
import QRCode from "react-qr-code";
import Flag from "react-world-flags";
import { resetSwapOrder } from "../../store/swapOrderSlice";
import { useMemo } from "react";
import { selectSharingpoint } from "../../store/sharingpointSlice";
import useInternetOnlineStatus from "../../shared/hooks/useInternetOnlineStatus";

const HomeLoggedOut = ({ handleTouchScreenEvent, station, scanRfidImg, user, changeLanguage, t }) => {
  const [userText, setUserText] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [scanImageLoaded, setScanImageLoaded] = useState(false);
  const [switchLanguageShown, setSwitchLanguageShown] = useState(false);
  const dispatch = useDispatch();

  const sharing_point_name = localStorage.getItem("my_sp_serial_number_is");
  const { isLoading } = useSelector((state) => state.login);
  const { internetAvailable } = useInternetOnlineStatus();


  const handleUserKeyPress = useCallback((event) => {
    const { key } = event;
    // console.log(switchLanguageShown);
    if (!["Shift", "Enter"].includes(key) && !switchLanguageShown) {
      setUserText((prevUserText) => `${prevUserText}${key}`);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const { sp_serial_number, is_online, is_swap_ongoing, validation_hash_key } =
    station ? station : {};

  const validateRFID = async (param_rfid) => {
    try {
      if ((!userText || userText.length !== 16) && !param_rfid) return;
      setIsLoading(true);
      const { data } = await axios.post(
        awsconfig.FLASK_REST_API + "/rfid/validate",
        {
          sp_serial_number: localStorage.getItem("my_sp_serial_number_is"),
          rfid_number: userText ? userText : param_rfid,
          client_id: awsconfig.aws_user_pools_web_client_id,
        }
      );

      const { name, refresh_token, access_token, id_token, is_admin } =
        data.data;

      localStorage.setItem("is_admin", is_admin);

      const session = new CognitoUserSession({
        IdToken: new CognitoIdToken({
          IdToken: id_token,
        }),
        RefreshToken: new CognitoRefreshToken({
          RefreshToken: "",
        }),
        AccessToken: new CognitoAccessToken({
          AccessToken: access_token,
        }),
      });
      Auth.configure(session, "session");
      const { username } = jwtDecode(access_token);

      const currentUser = Auth.createCognitoUser(
        session.getIdToken().decodePayload()["cognito:username"]
      );

      currentUser.setSignInUserSession(session);
      dispatch(
        loginSuccess({ name, username, access_token, id_token, refresh_token })
      );
      setUserText("");
      dispatch(resetSwapOrder());
      dispatch(selectSharingpoint({ sharing_point_name }));
    } catch (error) {
      setUserText("");
      toast.error(
        <NotificationContent
          iconName={"close"}
          message={error.response.data.message}
        />,
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        }
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    validateRFID();
  }, [userText]);
  useEffect(() => {
    dispatch(resetSwapOrder());
  }, []);

  const station_status = useMemo(() => {
    return is_online === "true";
  }, [station]);
  return (
    <div onClick={() => {handleTouchScreenEvent()}}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          top: "0px",
          bottom: "0px",
          margin: 0,
          position: "absolute",
          backgroundColor: "#ff5a00",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 20,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            {process.env.REACT_APP_MODE !== "production" && (
              <>
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#ff5a00",
                    float: "left",
                  }}
                  content="Unlock without card"
                  size="large"
                  circular
                  icon={"unlock"}
                  onClick={() => {
                    validateRFID("single-swap-bot-user");
                  }}
                />

                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#ff5a00",
                  }}
                  content="Unlock without card as admin"
                  size="large"
                  circular
                  icon={"unlock"}
                  onClick={() => {
                    validateRFID("415691A596C80");
                  }}
                />
              </>
            )}

            <Button
              className="shadow-button"
              style={{
                backgroundColor: "white",
                color: "#ff5a00",
                float: "left",
              }}
              content={t("Language")}
              size="large"
              circular
              icon={"globe"}
              onClick={() => {
                setSwitchLanguageShown(!switchLanguageShown);
              }}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <>
              {is_swap_ongoing && (
                <Label
                  className="shadow-button"
                  content={"swap ongoing"}
                  size="huge"
                  circular
                  style={{
                    backgroundColor: "white",
                    color: "#ff5a00",
                  }}
                />
              )}
              {internetAvailable && sp_serial_number &&
                <Label
                  className="shadow-button"
                  content={sp_serial_number}
                  size="huge"
                  circular
                  style={{
                    backgroundColor: "white",
                    color: "#ff5a00",
                  }}
                />
              }
              {!internetAvailable &&
                <Label
                  className="shadow-button"
                  content={"No Internet connection"}
                  size="huge"
                  circular
                  style={{
                    backgroundColor: "#444",
                    color: "white",

                    marginRight: 10,
                  }}
                />
              }
              {internetAvailable &&
                <Label
                  className="shadow-button is-online"
                  content={is_online && station_status ? "Online" : "Offline"}
                  size="huge"
                  circular
                  style={{
                    backgroundColor: "white",
                    color: "#ff5a00",
                    marginRight: 10,
                  }}
                />
              }
            </>
          </div>
        </div>
        {internetAvailable && station_status &&
          !(isLoading || loading || switchLanguageShown) &&
          station && (
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                height: "100%",
              }}
            >
              <div
                width="16"
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 700,
                      fontSize: 40,
                      color: "#fff",
                      padding: 40,
                    }}
                  >
                    {t("Scan rfid card")}
                  </div>
                  <img
                    alt=""
                    src={scanRfidImg}
                    height="350px"
                    width="auto"
                    onLoad={() => setScanImageLoaded(true)}
                  />
                </div>

                  <div
                    style={{
                      borderRadius: 30,
                      height: 100,
                      width: 250,
                      border: "solid white 10px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                    className="shadow-button"
                    onClick={() => navigate("/public-info")}
                  >
                    <div
                      style={{ fontWeight: 700, fontSize: 26, color: "#fff" }}
                    >
                      Station Info
                    </div>
                  </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: 40,
                        color: "#fff",
                        padding: 40,
                      }}
                    >
                      {t("Scan qr code")}
                    </div>
                  </div>

                  {validation_hash_key && (
                    <QRCode
                      style={{ color: "white", borderRadius: 10 }}
                      bgColor="#ff5a00"
                      fgColor="white"
                      value={`https://swobbee.de/dashboard-app?sp=${validation_hash_key}`}
                      size={350}
                    />
                  )}
                </div>
              </div>
            </div>
          )}


        {internetAvailable && !station && !(isLoading || loading || switchLanguageShown) && (
          <div
            width="16"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: 700,
                  fontSize: 40,
                  color: "#fff",
                  padding: 40,
                }}
              >
                {t("admin_setup_rfid_part_1")}
              </div>
              <img
                alt=""
                src={scanRfidImg}
                height="350px"
                width="auto"
                onLoad={() => setScanImageLoaded(true)}
              />
              <div
                style={{
                  fontWeight: 700,
                  fontSize: 40,
                  color: "#fff",
                  padding: 40,
                }}
              >
                {t("admin_setup_rfid_part_2")}
              </div>
            </div>
          </div>
        )}


        {!internetAvailable &&
          <StationOfflineMessage />
        }
        {!loading && station && !station_status && <StationOfflineMessage />}

        {internetAvailable && switchLanguageShown && !(isLoading || loading) && (
          <ChooseLanguage
            {...{ changeLanguage, t, setSwitchLanguageShown }}
          />
        )}

        {(loading || isLoading) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <BeatLoader
              color={"white"}
              loading={true}
              height={10}
              width={30}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ChooseLanguage = ({ t, changeLanguage, setSwitchLanguageShown }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Grid columns={2} textAlign="center" centered>
        <GridColumn style={{ color: "#fff" }} textAlign="center" width="16">
          <div style={{ fontWeight: 700, fontSize: 60, color: "#fff" }}>
            {t("Choose a language")}
          </div>
        </GridColumn>
        <GridColumn textAlign="right">
          <Button
            className="shadow-button"
            style={{
              backgroundColor: "white",
              color: "#ff5a00",
              borderRadius: 20,
              height: 200,
              width: 250,
            }}
            content={<Flag code="de" height="90" />}
            size="massive"
            circular
            onClick={() => {
              changeLanguage("de");
              setSwitchLanguageShown(false);
            }}
          />
        </GridColumn>
        <GridColumn textAlign="left">
          <Button
            className="shadow-button"
            style={{
              backgroundColor: "white",
              color: "#ff5a00",
              borderRadius: 20,
              height: 200,
              width: 250,
            }}
            content={<Flag code="us" height="90" />}
            size="massive"
            circular
            onClick={() => {
              changeLanguage("en");
              setSwitchLanguageShown(false);
            }}
          />
        </GridColumn>
        <GridColumn textAlign="right">
          <Button
            className="shadow-button"
            style={{
              backgroundColor: "white",
              color: "#ff5a00",
              borderRadius: 20,
              height: 200,
              width: 250,
            }}
            content={<Flag code="es" height="90" />}
            size="massive"
            circular
            onClick={() => {
              changeLanguage("es");
              setSwitchLanguageShown(false);
            }}
          />
        </GridColumn>
        <GridColumn textAlign="left">
          <Button
            className="shadow-button"
            style={{
              backgroundColor: "white",
              color: "#ff5a00",
              borderRadius: 20,
              height: 200,
              width: 250,
            }}
            content={<Flag code="fr" height="90" />}
            size="massive"
            circular
            onClick={() => {
              changeLanguage("fr");
              setSwitchLanguageShown(false);
            }}
          />
        </GridColumn>
      </Grid>
    </div>
  );
};

const StationOfflineMessage = () => (
  <div
    width="16"
    style={{
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      height: "100%",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          fontWeight: 700,
          fontSize: 40,
          color: "#fff",
          padding: 40,
        }}
      >
        I'm offline, please call support team for help.
      </div>
      <Icon name="warning sign" size="massive" style={{ color: "#fff" }} />
      <div
        style={{
          fontWeight: 700,
          fontSize: 40,
          color: "#fff",
          padding: 40,
        }}
      >
        +4930587047030
      </div>
    </div>
  </div>
);

export default HomeLoggedOut;
