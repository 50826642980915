import React, { useMemo } from "react";
import {
  GridColumn,
  Segment,
  Icon,
} from "semantic-ui-react";

const heightMapping = {
  5: 90,
  6: 75,
  7: 65,
  8: 55,
  9: 55,
  10: 55,
};

const ChargeModule = ({
  module_number,
  opened_doors,
  setSelectedModuleNumber,
  selectedModuleNumber,
  module_count,
}) => {
  return (
    <>
      <GridColumn style={{ padding: "2px 8px 2px 8px" }} width="7">
        <Segment
          style={{
            backgroundColor:
              module_number === selectedModuleNumber ? "#444" : "#D0D0D0",
            height: heightMapping[module_count],
          }}
          onClick={() =>
            opened_doors.includes(module_number) &&
            setSelectedModuleNumber(module_number)
          }
        >
          {opened_doors.includes(module_number) && (
            <Icon
              name={opened_doors.includes(module_number) ? "unlock" : "lock"}
              style={{
                color:
                  module_number === selectedModuleNumber ? "#D0D0D0" : "#444",
                marginTop: module_count <= 5 ? 16 : 8,
              }}
              size="big"
            />
          )}
        </Segment>
      </GridColumn>
      <GridColumn
        style={{ padding: 5, color: "#444" }}
        verticalAlign="middle"
        width="9"
      >
        {opened_doors.includes(module_number) && `Module ${module_number} open`}
      </GridColumn>
    </>
  );
};

const StateOfCharge = ({ index, selectedModuleNumber, box_status }) => {
  const state_of_charge = useMemo(
    () =>
      box_status.boxes.find((box) => box.box_id === +selectedModuleNumber)
        .slots[index].state_of_charge,
    [box_status]
  );
  return (
    <div className="text-inside-battery-icon">
      {state_of_charge && `${state_of_charge}%`}
    </div>
  );
};

export { heightMapping, ChargeModule, StateOfCharge }