import { useEffect, useState } from "react";

export default function useInternetOnlineStatus() {
  const [internetAvailable, setInternetAvailable] = useState(navigator.onLine);
  const onOffline = () => {
    setInternetAvailable(false)
    // console.log(`internet status: ${internetAvailable}`)
  }
  const onOnline = () => {
    setInternetAvailable(true)
    // console.log(`internet status: ${internetAvailable}`)
  }

  useEffect(() => {
    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);

    // cleanup if we unmount
    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
    };
  }, []);
  return { internetAvailable };
}
