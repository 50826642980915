import React, { useEffect, useMemo, useState } from "react";
import { Location, Router, Redirect, navigate } from "@reach/router";
import HomeLoggedOut from "./components/HomeLoggedOut";
import HomeLoggedIn from "./components/AdminLoggedIn";
import SelectBatteryType from "./components/SelectBatteryType";
import SingleSwap from "./components/SingleSwap";
import Settings from "./components/Settings";
import PublicInfo from "./components/PublicInfo";
import { useDispatch, useSelector } from "react-redux";
import { checkSession, logout } from "./store/loginSlice";
import { selectSharingpoint } from "./store/sharingpointSlice";
import Swap from "./components/Swap";
import "./App.css";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import awsconfig from "./aws-exports/aws-exports-rel";
import Versioning from "./components/Versioning";
import { persistStorage } from "./utils";
import scanRfidImg from "./assets/images/sticker_station_white.png";
import { ErrorBoundary } from "react-error-boundary";
import { Message } from "semantic-ui-react";
import OpenDoors from "./components/OpenDoors";
import { Auth, Hub } from "aws-amplify";
import { withNamespaces } from "react-i18next";
import i18n from "./i18n";


const App = ({ t }) => {
  const dispatch = useDispatch();
  const [station, setStation] = useState();
  const sharingpoints = useSelector((state) => state.sharingpoints);
  const { isLoggedIn } = useSelector((state) => state.login);
  let [user, setUser] = useState(null);

  useEffect(() => {
    persistStorage();
    dispatch(checkSession());
  }, []);

  const sharing_point_name = localStorage.getItem("my_sp_serial_number_is");
  useEffect(() => {
    if (!sharing_point_name) return;
    dispatch(selectSharingpoint({ sharing_point_name }));
  }, []);

  const handleTouchScreenEvent = () => {
    console.log("last_interaction_event_at", new Date());
    localStorage.setItem("last_touch_event_at", new Date().getTime());
  };

  const fetchStationMinimalData = async () => {
    try {
      const { data } = await axios.get(
        `${awsconfig.FLASK_REST_API}/sharingpoints/${sharing_point_name}/minimal`
      );
      setStation(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!sharing_point_name) return;
    fetchStationMinimalData();
    const intervalId = () => {
      setInterval(() => {
        fetchStationMinimalData();
      }, 7000);
    };
    intervalId();
    return function cleanup() {
      clearInterval(intervalId);
      setStation(null)
    };
  }, [isLoggedIn]);
  const { selectedSharingpoint } = sharingpoints;

  const is_admin = useMemo(() => {
    return localStorage.getItem("is_admin") === "true";
  }, [user]);

  const is_allowed_to_fill_in = useMemo(() => {
    if (!selectedSharingpoint) return;

    return selectedSharingpoint.allowed_to_fill_in;
  }, [selectedSharingpoint]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  function ErrorFallback({ error }) {
    return (
      <Message
        style={{ margin: 40 }}
        error
        header="Something went wrong"
        list={[error.message]}
      />
    );
  }

  useEffect(() => {
    let updateUser = async (authState) => {
      try {
        let user = await Auth.currentAuthenticatedUser();
        setUser(user);
      } catch {
        setUser(null);
      }
    };
    Hub.listen("auth", updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove("auth", updateUser); // cleanup
  }, []);

  useEffect(() => {
    if (user) {
      if (is_admin) {
        navigate("/admin-logged-in")
        console.log(`navigate to /admin-logged-in`)
      } else {
        if (typeof (is_allowed_to_fill_in) !== "undefined") {
          if (is_allowed_to_fill_in) {
            navigate("/open-doors")
            console.log(`navigate to /open-doors`)
          } else {
            navigate("/single-swap")
            console.log(`navigate to /single-swap`)
          }
        }
      }
    }
  }, [is_admin, is_allowed_to_fill_in, user]);

  return (
    <div id="app">
      <Location>
        {() => (
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={() => setTimeout(() => dispatch(logout()), 30000)}
          >
            <Router>
              {user &&
                <>
                  <HomeLoggedIn
                    {...{
                      is_admin,
                      is_allowed_to_fill_in,
                      station,
                      handleTouchScreenEvent,
                      t
                    }}
                    path="/admin-logged-in"
                  />

                  <SelectBatteryType
                    {...{
                      is_allowed_to_fill_in,
                      station,
                      handleTouchScreenEvent,
                      is_admin,
                      t
                    }}
                    path={"/batteries"}
                  />

                  <SingleSwap
                    {...{
                      is_allowed_to_fill_in,
                      station,
                      handleTouchScreenEvent,
                      is_admin,
                      t
                    }}
                    path={"/single-swap"}
                  />

                  <OpenDoors
                    {...{
                      is_allowed_to_fill_in,
                      station,
                      handleTouchScreenEvent,
                      is_admin,
                      t
                    }}
                    path={"/open-doors"}
                  />
                  <Swap
                    path="/swap/:swapId"
                    {...{
                      station,
                      handleTouchScreenEvent,
                      t
                    }}
                  />
                  <Settings path="/settings" {...{ station, t }} />
                </>
              }
              <HomeLoggedOut
                {...{ handleTouchScreenEvent, station, scanRfidImg, user, changeLanguage, t }}
                path="/"
              />
              <PublicInfo
                {...{ handleTouchScreenEvent, station, t }}
                path="/public-info"
              />
              <Redirect noThrow from="*" to="/" />
            </Router>
          </ErrorBoundary>
        )}
      </Location>

      <Versioning />
      <ToastContainer />
    </div>
  );
};

export default withNamespaces()(App);
