import { createSlice } from "@reduxjs/toolkit";

const appStateSlice = createSlice({
  name: "user",
  initialState: {
    isOpenSwapModal: false,
    isOpenBatterySelection: false,
    isOpenSetBatteryNumber: false,
    isOpenBurgerMenu: false,
    isOpenFilterModal: false,
    isLoading: false,
    isPostOrderModal: false,
    defaultUser: false
  },
  reducers: {
    closeSwapModal: state => {
      state.isOpenSwapModal = false;
      localStorage.removeItem("validation")

    },
    openSwapModal: state => {
      state.isOpenSwapModal = true;
    },
    showBatterySelection: state => {
      state.isOpenBatterySelection = true;
    },
    hideBatterySelection: state => {
      state.isOpenBatterySelection = false;
    },
    showSetBatteryNumber: state => {
      state.isOpenSetBatteryNumber = true;
    },
    hideSetBatteryNumber: state => {
      state.isOpenSetBatteryNumber = false;
    },
    showBurgerMenu: state => {
      state.isOpenBurgerMenu = true;
    },
    hideBurgerMenu: state => {
      state.isOpenBurgerMenu = false;
    },
    redirectToSwapOrder: (state, actions) => {
      window.location = "/sharingpoint/1/order"
    },
    setLoading: (state, actions) => {
      state.isLoading = actions.payload
    },
    showFilterModal: state => {
      state.isOpenFilterModal = true;
    },
    hideShowFilterModal: state => {
      state.isOpenFilterModal = false;
    },
    showPostOrderModal: state => {
      state.isPostOrderModal = true;
    },
    hidePostOrderModal: state => {
      state.isPostOrderModal = false;
    },
    setDefaultUserIsLoggedIn: (state) => {
      state.defaultUser = true;
    },
    setDefaultUserIsLoggedOut: (state) => {
      state.defaultUser = false;
    }
  }
});

export const {
  closeSwapModal,
  openSwapModal,
  showBatterySelection,
  hideBatterySelection,
  showSetBatteryNumber,
  hideSetBatteryNumber,
  hideBurgerMenu,
  showBurgerMenu,
  redirectToSwapOrder,
  setLoading,
  showFilterModal,
  hideShowFilterModal,
  showPostOrderModal,
  hidePostOrderModal,
  setDefaultUserIsLoggedIn,
  setDefaultUserIsLoggedOut
} = appStateSlice.actions;
export default appStateSlice.reducer;
