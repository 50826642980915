import React, { useEffect, useMemo, useState } from "react";
import {
  fetchSwapOrderBySpName,
  confirm_open_door,
  cancel_order,
  resetSwapOrder,
} from "../../store/swapOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  GridColumn,
  Segment,
  Button,
  Header,
  Icon,
  List,
  ListItem,
  Statistic,
  Placeholder,
  Label,
} from "semantic-ui-react";
import BatteryImage from "../BatteryImage";
import { lowerCase, isEmpty } from "lodash";

import { BeatLoader } from "react-spinners";
import { logout } from "../../store/loginSlice";
import {
  useGetRequestUserRatingQuery,
  useGetServiceRatingOptionsQuery,
  useUpdateRatingMutation,
} from "../../store/services/servicesRating"
import SwapMessage from "../SwapMessage";
import HeaderLayout from "../HeaderLayout";
import { upperFirst } from "lodash";
import useTimeout from "../../shared/hooks/useTimeout"
import LogoutAlert from "../LogoutAlert"
import usePrevious from "../../shared/hooks/usePrevious";

import NotificationContent from "../NotificationContent";
import RatingSection from "../ServiceRatting/RatingSection"
import { toast } from "react-toastify";
import { t } from "i18next";
import { ChargeModule, StateOfCharge } from "../SingleSwap/SubComponents";


const iconMapping = {
  Present: require("../../assets/images/slots/slot_is_present.png"),
  Ready: require("../../assets/images/slots/slot_is_ready.png"),
  Empty: require("../../assets/images/slots/minus.png"),
  Error: require("../../assets/images/slots/battery-state-error.png"),
};

export default ({
  handleTouchScreenEvent,
  swapId,
  station,
}) => {
  const dispatch = useDispatch();
  const [showLogoutAlert, setShowLogoutAlert] = useTimeout()

  const [toastifyIdProblemMapping, setToastifyIdProblemMapping] = useState({});

  const { isLoadingLogout } = useSelector((state) => state.login);
  const { mySwapOrder, isLoadingConfirmOpenDoor, isLoadingForceCompletion } =
    useSelector((state) => state.swapOrder);

  const { boxes } = mySwapOrder.box_status || {}
  const prevDeeplyNestedBoxesObject = usePrevious(boxes)

  const [showServiceRating, setShowServiceRating] = useState(false)

  useEffect(() => {
    dispatch(fetchSwapOrderBySpName(swapId));
  }, [swapId]);

  useEffect(() => {
    let timeout;
    if (
      [
        "ABORTED",
        "COMPLETED",
        "COMPLETED_WITH_PROBLEMS",
        "ABORTED_WITH_PROBLEMS",
        "CANCELED_WITH_PROBLEMS ",
        "LESS_TAKEN_THAN_ORDERED",
        "MORE_TAKEN_THAN_ORDERED",
      ].includes(mySwapOrder.swap_state)
    ) {
      if (toastifyIdProblemMapping) {
        Object.values(toastifyIdProblemMapping).forEach((item) => {
          toast.dismiss(item);
        });
      }
    }

    if (["OPEN"].includes(mySwapOrder.swap_state)) {
      if (timeout) timeout.clearTimeout();
    }

    // return () => {
    //   if (timeout) clearTimeout(timeout);
    // };
  }, [mySwapOrder]);

  const intervalId = () => {
    // console.log("intervalId", swapId);
    return setInterval(() => {
      dispatch(fetchSwapOrderBySpName(swapId));
    }, 2000);
  };

  useEffect(() => {
    let intervalIdparam = intervalId();
    // console.log("intervalIdparam", intervalIdparam);
    return () => {
      // console.log("intervalIdparam", intervalIdparam);
      // console.log("clearInterval", swapId);
      clearInterval(intervalIdparam);
      dispatch(resetSwapOrder());
    };
  }, []);

  const {
    battery_count,
    battery_type,
    swap_state,
    swap_status_map,
    box_status,
    confirmation_token,
    problems,
  } = mySwapOrder;
  const prevSwapStatusMap = usePrevious(swap_status_map)
  const [selectedModuleNumber, setSelectedModuleNumber] = useState(
    mySwapOrder.swap_status_map &&
    Object.keys(JSON.parse(mySwapOrder.swap_status_map))[0]
  );
  const hasUnsolvedProblems = useMemo(() => {
    if (!mySwapOrder || !problems) return;
    Object.values(JSON.parse(problems)).forEach(({ solved }) => {
      if (solved) return solved;
    });
  }, [problems]);

  useEffect(() => {
    let temp = {};
    // white labeling push notification if not solved
    if (!mySwapOrder || !problems) return;
    Object.keys(JSON.parse(problems)).forEach((key) => {
      if (
        swap_state === "OPEN" &&
        !JSON.parse(problems)[key].solved &&
        (!toastifyIdProblemMapping || !toastifyIdProblemMapping[key])
      ) {
        let tostifyId = toast.error(
          <NotificationContent
            iconName={"close"}
            message={`${lowerCase(
              JSON.parse(problems)[key].problem.replaceAll("_", " ")
            )} at slot ${JSON.parse(problems)[key].slot_number} in module ${JSON.parse(problems)[key].box_number
              }`}
          />,
          {
            position: "bottom-right",
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: false,
            progress: false,
            autoClose: false,
            closeOnClick: false,
          }
        );
        temp[key] = tostifyId;
      }
      if (!isEmpty(temp)) setToastifyIdProblemMapping(temp);
      // white labeling dismiss notification if solved
      if (toastifyIdProblemMapping && JSON.parse(problems)[key].solved) {
        toast.dismiss(toastifyIdProblemMapping[key]);
      }
    });
  }, [swap_state, problems]);

  const doors = useMemo(() => {
    if (!swap_status_map) return;
    return Object.keys(JSON.parse(swap_status_map)).join(" & ");
  }, [swap_status_map]);

  const build_warning_message = () => {
    let warnings = [];
    if (+battery_count > +box_status.global_box_in) {
      warnings = [...warnings, "You put in less batteries than ordered."];
    }
    if (+battery_count > +box_status.global_box_out) {
      warnings = [...warnings, "You took out less batteries than ordered."];
    }

    if (+battery_count < +box_status.global_box_in) {
      warnings = [...warnings, "You put in more batteries than ordered."];
    }
    if (+battery_count < +box_status.global_box_out) {
      warnings = [...warnings, "You took out more batteries than ordered."];
    }

    if (+box_status.global_box_in > +box_status.global_box_out) {
      warnings = [...warnings, "You took out less batteries than put in."];
    }

    if (+box_status.global_box_in < +box_status.global_box_out) {
      warnings = [...warnings, "You took out more batteries than put in."];
    }

    if (swap_state === "HAS_PROBLEMS") {
      warnings = [...warnings, "unfollowed battery inserted"];
    }

    return warnings;
  };

  const messages = useMemo(() => {
    if (!mySwapOrder) return;
    return build_warning_message();
  }, [mySwapOrder]);

  const opened_doors = useMemo(() => {
    if (!mySwapOrder.swap_status_map) return;
    // console.log("boxes");
    console.log(swap_status_map);
    if (
      JSON.stringify(prevDeeplyNestedBoxesObject) !== JSON.stringify(boxes) ||
      JSON.stringify(prevSwapStatusMap) !== JSON.stringify(swap_status_map)
    ) {
      console.log("Reset timeout due to recent interaction on station")
      handleTouchScreenEvent()
    }

    if (
      selectedModuleNumber === undefined ||
      selectedModuleNumber === null ||
      +selectedModuleNumber === -1
    ) {
      setSelectedModuleNumber(
        Object.keys(JSON.parse(swap_status_map))[
        selectedModuleNumber ? selectedModuleNumber : 0
        ]
      );
    }

    return Object.keys(JSON.parse(mySwapOrder.swap_status_map));
  }, [mySwapOrder]);

  const {
    data: options,
  } = useGetServiceRatingOptionsQuery()
  const {
    data: requestUserRating,
  } = useGetRequestUserRatingQuery()
  const [updateRating] = useUpdateRatingMutation()

  const handelOnClickFinish = () => {
    const should_request_user_rating = requestUserRating.data.request_user_rating || localStorage.getItem('mock-request-user-rating')
    // const should_request_user_rating = true
    console.log("request_user_rating: ", requestUserRating.data)
    if (should_request_user_rating)
      setShowServiceRating(true)
    else
    dispatch(logout());
  }  

  const handelOnSendingRating = (rating, reason) => {
    const swap_validation_hash_key = mySwapOrder.validation_hash_key
    updateRating({ rating, reason, validation_hash_key: swap_validation_hash_key })
    dispatch(logout());
  }

  const handelOnIgnoreRating = () => {
    dispatch(logout());
  }

  return (
    <Grid onClick={() => handleTouchScreenEvent()}>
      <HeaderLayout title={"Swap"} station={station} />
      {showLogoutAlert ? <LogoutAlert setShowLogoutAlert={setShowLogoutAlert} /> :
        (
          showServiceRating ?
            <div style={{
              margin: "auto",
              width: 1200,
              display: "flex",
              flexDirection: "column",

            }}>
              <div style={{ height: "220px" }}></div>
              <RatingSection {...{ t, handelOnSendingRating, handelOnIgnoreRating }} options={options.data} />
            </div>
            :
            <>
              {!mySwapOrder && (
                <div style={{ marginTop: 400, marginLeft: 600, zIndex: 60 }}>
                  <BeatLoader
                    color={"#ff5a00"}
                    loading={true}
                    height={10}
                    width={30}
                    style={{ zIndex: 50 }}
                  />
                </div>
              )}
              {mySwapOrder && (
                <Segment
                  style={{
                    minHeight: 500,
                    borderRadius: 50,
                    backgroundColor: "#ff5a00",
                    position: "fixed",
                    top: 120,
                    left: 60,
                    zIndex: 40,
                    width: 1150,
                  }}
                >
                  {[
                    "LESS_TAKEN_THAN_ORDERED",
                    "MORE_TAKEN_THAN_ORDERED",
                    "MORE_INSERTED_THAN_ORDERED",
                    "LESS_INSERTED_THAN_ORDERED",
                    "HAS_PROBLEMS",
                  ].includes(swap_state) && (
                      <SwapMessage
                        Left={
                          <Icon
                            name="warning sign"
                            style={{ color: "#444" }}
                            size="massive"
                          />
                        }
                        Right={
                          <Grid verticalAlign="middle" textAlign="center">
                            <GridColumn width="16" textAlign="center">
                              <Statistic style={{ margin: "0px 20px 0px 20px" }}>
                                <Statistic.Label style={{ color: "#444" }}>
                                  Requested
                                </Statistic.Label>
                                <Statistic.Value style={{ color: "#444" }}>
                                  {battery_count}
                                </Statistic.Value>
                              </Statistic>

                              <Statistic>
                                <Statistic.Label style={{ color: "#444" }}>
                                  In/Out
                                </Statistic.Label>
                                <Statistic.Value style={{ color: "#444" }}>
                                  {box_status.global_box_in}/{box_status.global_box_out}
                                </Statistic.Value>
                              </Statistic>
                            </GridColumn>

                            <GridColumn width="16">
                              <Header
                                as="h3"
                                style={{ fontColor: "#444", color: "#444" }}
                              >
                                We recommend swapping one battery for another for an
                                efficient use of the station.
                              </Header>
                            </GridColumn>

                            <GridColumn width="16" textAlign="center">
                              <List>
                                {messages.map((item) => (
                                  <ListItem style={{ color: "#444" }} key={item}>
                                    <Header
                                      as="h3"
                                      style={{ fontColor: "#444", color: "#444" }}
                                    >
                                      {
                                        <ListItem>
                                          <Header
                                            as="h3"
                                            style={{ fontColor: "#444", color: "#444" }}
                                          >
                                            {item}
                                          </Header>
                                        </ListItem>
                                      }
                                    </Header>
                                  </ListItem>
                                ))}
                              </List>

                              <Header
                                as="h3"
                                style={{ fontColor: "#444", color: "#444" }}
                              >
                                Do you want to reopen doors and fix it?
                              </Header>
                            </GridColumn>

                            <GridColumn width="8" textAlign="center">
                              <Button
                                loading={isLoadingConfirmOpenDoor}
                                circular
                                fluid
                                style={{
                                  backgroundColor: "#ff5a00",
                                  color: "white",
                                }}
                                size="massive"
                                width="8"
                                content="Yes"
                                onClick={() => {
                                  dispatch(confirm_open_door(confirmation_token));
                                }}
                              />
                            </GridColumn>
                            <GridColumn width="8" textAlign="center">
                              <Button
                                loading={isLoadingForceCompletion}
                                circular
                                fluid
                                style={{
                                  backgroundColor: "#444",
                                  color: "white",
                                }}
                                size="massive"
                                width="8"
                                content="No"
                                onClick={() => dispatch(cancel_order(confirmation_token))}
                              />
                            </GridColumn>
                          </Grid>
                        }
                      />
                    )}

                  {[
                    "COMPLETED",
                    "CANCELED_WITH_PROBLEMS",
                    "COMPLETED_WITH_PROBLEMS",
                  ].includes(swap_state) && (
                      <SwapMessage
                        Left={
                          <>
                            <BatteryImage
                              type={battery_type}
                              height={250}
                              width={"auto"}
                              text={"text"}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                fontWeight: "bold",
                                fontSize: 40,
                                color: "white",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              {"COMPLETED"}
                            </div>
                          </>
                        }
                        Right={
                          <Grid style={{ width: 450, margin: "auto", marginTop: 0 }}>
                            <GridColumn width="16">
                              <Icon name="check circle" color="green" size="massive" />
                            </GridColumn>
                            <GridColumn width="7" style={{ paddingTop: 6, margin: 0 }}>
                              <span
                                style={{
                                  fontSize: 24,
                                  fontColor: "#444",
                                  color: "#444",
                                  fontWeight: 1,
                                  float: "left",
                                }}
                              >
                                Battery type
                              </span>
                            </GridColumn>
                            <GridColumn
                              width="9"
                              textAlign="right"
                              style={{ paddingTop: 6, margin: 0 }}
                            >
                              <span
                                as="h2"
                                style={{
                                  fontColor: "#444",
                                  color: "#444",
                                  fontSize: 24,
                                  fontWeight: 1,
                                  float: "right",
                                }}
                              >
                                {upperFirst(battery_type)}
                              </span>
                            </GridColumn>
                            {battery_count > 0 && (
                              <>
                                <GridColumn
                                  width="10"
                                  style={{ paddingTop: 6, margin: 0 }}
                                >
                                  <span
                                    style={{
                                      fontSize: 24,
                                      fontColor: "#444",
                                      color: "#444",
                                      fontWeight: 1,
                                      float: "left",
                                    }}
                                  >
                                    Requested
                                  </span>
                                </GridColumn>
                                <GridColumn
                                  width="6"
                                  textAlign="right"
                                  style={{ paddingTop: 6, margin: 0 }}
                                >
                                  <span
                                    as="h2"
                                    style={{
                                      fontColor: "#444",
                                      color: "#444",
                                      fontSize: 24,
                                      fontWeight: 1,
                                      float: "right",
                                    }}
                                  >
                                    {battery_count}
                                  </span>
                                </GridColumn>
                              </>
                            )}

                            <GridColumn
                              width="10"
                              textAlign="left"
                              style={{ paddingTop: 6, margin: 0 }}
                            >
                              <span
                                style={{
                                  fontSize: 24,
                                  fontColor: "#444",
                                  color: "#444",
                                  fontWeight: 1,
                                  float: "left",
                                }}
                              >
                                In/Out
                              </span>
                            </GridColumn>
                            <GridColumn
                              width="6"
                              textAlign="right"
                              style={{ paddingTop: 6, margin: 0 }}
                            >
                              <span
                                as="h2"
                                style={{
                                  fontColor: "#444",
                                  color: "#444",
                                  fontSize: 24,
                                  fontWeight: 1,
                                  float: "right",
                                }}
                              >
                                {mySwapOrder.box_status.global_box_in}/
                                {mySwapOrder.box_status.global_box_out}
                              </span>
                            </GridColumn>

                            <GridColumn
                              width="10"
                              textAlign="left"
                              style={{ paddingTop: 6, margin: 0 }}
                            >
                              <span
                                style={{
                                  fontSize: 24,
                                  fontColor: "#444",
                                  color: "#444",
                                  fontWeight: 1,
                                  float: "left",
                                }}
                              >
                                Doors
                              </span>
                            </GridColumn>
                            <GridColumn
                              width="6"
                              textAlign="right"
                              style={{ paddingTop: 6, margin: 0 }}
                            >
                              <span
                                as="h2"
                                style={{
                                  fontColor: "#444",
                                  color: "#444",
                                  fontSize: 24,
                                  fontWeight: 1,
                                  float: "right",
                                }}
                              >
                                {doors}
                              </span>
                            </GridColumn>

                            <GridColumn
                              width="10"
                              textAlign="left"
                              style={{ paddingTop: 6, margin: 0 }}
                            >
                              <span
                                style={{
                                  fontSize: 24,
                                  fontColor: "#444",
                                  color: "#444",
                                  fontWeight: 1,
                                  float: "left",
                                }}
                              >
                                Has problems
                              </span>
                            </GridColumn>
                            <GridColumn
                              width="6"
                              textAlign="right"
                              style={{ paddingTop: 6, margin: 0 }}
                            >
                              <span
                                as="h2"
                                style={{
                                  fontColor: "#444",
                                  color: "#444",
                                  fontSize: 24,
                                  fontWeight: 1,
                                  float: "right",
                                }}
                              >
                                {swap_state.includes("PROBLEMS") ? "Yes" : "No"}
                              </span>
                            </GridColumn>

                            <Button
                              loading={isLoadingLogout}
                              circular
                              style={{
                                position: "absolute",
                                bottom: 20,
                                backgroundColor: "#ff5a00",
                                color: "white",
                                width: 300,
                                marginLeft: 80,
                              }}
                              size="massive"
                              width="8"
                              content="Finish"
                              onClick={handelOnClickFinish}
                            />
                          </Grid>
                        }
                      />
                    )}

                  {["ABORTED", "ABORTED_WITH_PROBLEMS"].includes(swap_state) && (
                    <SwapMessage
                      Left={
                        <Icon
                          name="warning circle"
                          style={{ color: "#444" }}
                          size="massive"
                        />
                      }
                      Right={
                        <>
                          <p
                            style={{
                              fontColor: "#444",
                              color: "#444",
                              margin: "auto",
                              fontSize: 35,
                              marginTop: 190,
                            }}
                          >
                            Swap aborted for inactivity!
                          </p>

                          <Button
                            loading={isLoadingLogout}
                            circular
                            style={{
                              backgroundColor: "#ff5a00",
                              color: "white",
                              position: "absolute",
                              bottom: 30,
                              left: 200,
                            }}
                            size="massive"
                            content="Finish"
                            onClick={() => {
                              dispatch(logout());
                            }}
                          />
                        </>
                      }
                    />
                  )}
                  {/* {(["OPEN"].includes(swap_state) || !doors_are_locked) && ( */}
                  {["OPEN"].includes(swap_state) && (
                    <SwapMessage
                      Right={
                        <Grid verticalAlign="middle" textAlign="center">
                          <GridColumn width="16" textAlign="left">
                            <Header
                              as="h1"
                              style={{
                                color: "#444",
                                paddingBottom: 0,
                                marginBottom: 0,
                              }}
                            >
                              {selectedModuleNumber && +selectedModuleNumber !== -1 ? (
                                `Module ${selectedModuleNumber}`
                              ) : (
                                <Placeholder>
                                  <Placeholder.Line length={"short"} />
                                </Placeholder>
                              )}
                            </Header>

                            {box_status && (
                              <>
                                <Header
                                  as="h2"
                                  style={{ color: "#444", paddingTop: 0, marginTop: 0 }}
                                >
                                  {upperFirst(battery_type)} In:{" "}
                                  {box_status.global_box_in} / Out:{" "}
                                  {box_status.global_box_out}
                                </Header>
                              </>
                            )}
                          </GridColumn>
                          <GridColumn width="8">
                            {hasUnsolvedProblems && (
                              <Label
                                circular
                                color="grey"
                                size="massive"
                                style={{ float: "right" }}
                                content="Problem"
                              />
                            )}
                          </GridColumn>
                          <GridColumn width="16">
                            <div
                              size="medium"
                              style={{ color: "#444", fontSize: 22, fontWeight: 300 }}
                            >
                              {t("swap_first_message")}
                            </div>
                          </GridColumn>
                          <GridColumn width="16" textAlign="center">
                            {selectedModuleNumber &&
                              box_status.boxes &&
                              box_status.boxes.length > 0 && (
                                <Grid
                                  centered
                                  style={{
                                    width: 500,
                                  }}
                                  className="module"
                                >
                                  {box_status &&
                                    box_status.boxes &&
                                    box_status.boxes.length > 0 &&
                                    box_status.boxes.find(
                                      (box) => box.box_id === +selectedModuleNumber
                                    ) && (
                                      <>
                                        {["greenpack", "torrot", "kumpan"].includes(
                                          battery_type
                                        ) && (
                                            <GridColumn width="16">
                                              <div className="slot">
                                                {box_status.boxes.find(
                                                  (box) =>
                                                    box.box_id === +selectedModuleNumber
                                                ).slots[0].state !== "Empty" ? (
                                                  <>
                                                    <img
                                                      src={
                                                        iconMapping[
                                                        box_status.boxes.find(
                                                          (box) =>
                                                            box.box_id ===
                                                            +selectedModuleNumber
                                                        ).slots[0].state
                                                        ]
                                                      }
                                                      alt=""
                                                      className="one-slot-sp-icon"
                                                    />

                                                    <StateOfCharge
                                                      index={0}
                                                      {...{
                                                        selectedModuleNumber,
                                                        box_status,
                                                      }}
                                                    />
                                                  </>
                                                ) : (
                                                  <Icon
                                                    name="minus"
                                                    size="huge"
                                                    style={{ color: "#444", margin: 5 }}
                                                  />
                                                )}
                                              </div>
                                            </GridColumn>
                                          )}

                                        {["Okai B", "Segway"].includes(
                                          battery_type
                                        ) && (
                                            <>
                                              <GridColumn width="8">
                                                <div className="slot">
                                                  {box_status.boxes.find(
                                                    (box) =>
                                                      box.box_id === +selectedModuleNumber
                                                  ).slots[0].state !== "Empty" ? (
                                                    <>
                                                      <img
                                                        src={
                                                          iconMapping[
                                                          box_status.boxes.find(
                                                            (box) =>
                                                              box.box_id ===
                                                              +selectedModuleNumber
                                                          ).slots[0].state
                                                          ]
                                                        }
                                                        alt=""
                                                        className="foure-slot-sp-icon"
                                                      />
                                                      <StateOfCharge
                                                        index={0}
                                                        {...{
                                                          selectedModuleNumber,
                                                          box_status,
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    <Icon
                                                      name="minus"
                                                      size="huge"
                                                      style={{ color: "#444", margin: 5 }}
                                                    />
                                                  )}
                                                </div>
                                              </GridColumn>
                                              <GridColumn width="8">
                                                <div className="slot">
                                                  {box_status.boxes.find(
                                                    (box) =>
                                                      box.box_id === +selectedModuleNumber
                                                  ).slots[2].state !== "Empty" ? (
                                                    <>
                                                      <img
                                                        src={
                                                          iconMapping[
                                                          box_status.boxes.find(
                                                            (box) =>
                                                              box.box_id ===
                                                              +selectedModuleNumber
                                                          ).slots[2].state
                                                          ]
                                                        }
                                                        alt=""
                                                        className="foure-slot-sp-icon"
                                                      />
                                                      <StateOfCharge
                                                        index={2}
                                                        {...{
                                                          selectedModuleNumber,
                                                          box_status,
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    <Icon
                                                      name="minus"
                                                      size="huge"
                                                      style={{ color: "#444", margin: 5 }}
                                                    />
                                                  )}
                                                </div>
                                              </GridColumn>
                                              <GridColumn width="8">
                                                <div className="slot">
                                                  {box_status.boxes.find(
                                                    (box) =>
                                                      box.box_id === +selectedModuleNumber
                                                  ).slots[1].state !== "Empty" ? (
                                                    <>
                                                      <img
                                                        src={
                                                          iconMapping[
                                                          box_status.boxes.find(
                                                            (box) =>
                                                              box.box_id ===
                                                              +selectedModuleNumber
                                                          ).slots[1].state
                                                          ]
                                                        }
                                                        alt=""
                                                        className="foure-slot-sp-icon"
                                                      />

                                                      <StateOfCharge
                                                        index={1}
                                                        {...{
                                                          selectedModuleNumber,
                                                          box_status,
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    <Icon
                                                      name="minus"
                                                      size="huge"
                                                      style={{ color: "#444", margin: 5 }}
                                                    />
                                                  )}
                                                </div>
                                              </GridColumn>
                                              <GridColumn width="8">
                                                <div className="slot">
                                                  {box_status.boxes.find(
                                                    (box) =>
                                                      box.box_id === +selectedModuleNumber
                                                  ).slots[3].state !== "Empty" ? (
                                                    <>
                                                      <img
                                                        src={
                                                          iconMapping[
                                                          box_status.boxes.find(
                                                            (box) =>
                                                              box.box_id ===
                                                              +selectedModuleNumber
                                                          ).slots[3].state
                                                          ]
                                                        }
                                                        alt=""
                                                        className="foure-slot-sp-icon"
                                                      />
                                                      <StateOfCharge
                                                        index={3}
                                                        {...{
                                                          selectedModuleNumber,
                                                          box_status,
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    <Icon
                                                      name="minus"
                                                      size="huge"
                                                      style={{ color: "#444", margin: 5 }}
                                                    />
                                                  )}
                                                </div>
                                              </GridColumn>
                                            </>
                                          )}

                                        {["AES eBike Akku 2.0 48V"].includes(
                                          battery_type
                                        ) && (
                                            <>
                                              <GridColumn
                                                width="4"
                                                verticalAlign="middle"
                                              >
                                                <div className="slot">
                                                  {box_status.boxes.find(
                                                    (box) =>
                                                      box.box_id === +selectedModuleNumber
                                                  ).slots[0].state !== "Empty" ? (
                                                    <>
                                                      <img
                                                        src={
                                                          iconMapping[
                                                          box_status.boxes.find(
                                                            (box) =>
                                                              box.box_id ===
                                                              +selectedModuleNumber
                                                          ).slots[0].state
                                                          ]
                                                        }
                                                        alt=""
                                                        className="slot-icon"
                                                      />
                                                      <StateOfCharge
                                                        index={0}
                                                        {...{
                                                          selectedModuleNumber,
                                                          box_status,
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    <Icon
                                                      name="minus"
                                                      size="huge"
                                                      style={{ color: "#444", margin: 5 }}
                                                    />
                                                  )}
                                                </div>
                                              </GridColumn>
                                              <GridColumn width="4">
                                                <div className="slot">
                                                  {box_status.boxes.find(
                                                    (box) =>
                                                      box.box_id === +selectedModuleNumber
                                                  ).slots[1].state !== "Empty" ? (
                                                    <>
                                                      <img
                                                        src={
                                                          iconMapping[
                                                          box_status.boxes.find(
                                                            (box) =>
                                                              box.box_id ===
                                                              +selectedModuleNumber
                                                          ).slots[1].state
                                                          ]
                                                        }
                                                        alt=""
                                                        className="slot-icon"
                                                      />
                                                      <StateOfCharge
                                                        index={1}
                                                        {...{
                                                          selectedModuleNumber,
                                                          box_status,
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    <Icon
                                                      name="minus"
                                                      size="huge"
                                                      style={{ color: "#444", margin: 5 }}
                                                    />
                                                  )}
                                                </div>
                                              </GridColumn>
                                              <GridColumn width="4">
                                                <div className="slot">
                                                  {box_status.boxes.find(
                                                    (box) =>
                                                      box.box_id === +selectedModuleNumber
                                                  ).slots[2].state !== "Empty" ? (
                                                    <>
                                                      <img
                                                        src={
                                                          iconMapping[
                                                          box_status.boxes.find(
                                                            (box) =>
                                                              box.box_id ===
                                                              +selectedModuleNumber
                                                          ).slots[2].state
                                                          ]
                                                        }
                                                        alt=""
                                                        className="slot-icon"
                                                      />
                                                      <StateOfCharge
                                                        index={2}
                                                        {...{
                                                          selectedModuleNumber,
                                                          box_status,
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    <Icon
                                                      name="minus"
                                                      size="huge"
                                                      style={{ color: "#444", margin: 5 }}
                                                    />
                                                  )}
                                                </div>
                                              </GridColumn>
                                              <GridColumn width="4">
                                                <div className="slot">
                                                  {box_status.boxes.find(
                                                    (box) =>
                                                      box.box_id === +selectedModuleNumber
                                                  ).slots[3].state !== "Empty" ? (
                                                    <>
                                                      <img
                                                        src={
                                                          iconMapping[
                                                          box_status.boxes.find(
                                                            (box) =>
                                                              box.box_id ===
                                                              +selectedModuleNumber
                                                          ).slots[3].state
                                                          ]
                                                        }
                                                        alt=""
                                                        className="slot-icon"
                                                      />
                                                      <StateOfCharge
                                                        index={3}
                                                        {...{
                                                          selectedModuleNumber,
                                                          box_status,
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    <Icon
                                                      name="minus"
                                                      size="huge"
                                                      style={{ color: "#444", margin: 5 }}
                                                    />
                                                  )}
                                                </div>
                                              </GridColumn>
                                            </>
                                          )}

                                        {["Okai A"].includes(battery_type) && (
                                          <>
                                            <GridColumn
                                              width="5"
                                              verticalAlign="middle"
                                            >
                                              <div className="slot">
                                                {box_status.boxes.find(
                                                  (box) =>
                                                    box.box_id === +selectedModuleNumber
                                                ).slots[0].state !== "Empty" ? (
                                                  <>
                                                    <img
                                                      src={
                                                        iconMapping[
                                                        box_status.boxes.find(
                                                          (box) =>
                                                            box.box_id ===
                                                            +selectedModuleNumber
                                                        ).slots[0].state
                                                        ]
                                                      }
                                                      alt=""
                                                      className="slot-icon"
                                                    />
                                                    <StateOfCharge
                                                      index={0}
                                                      {...{
                                                        selectedModuleNumber,
                                                        box_status,
                                                      }}
                                                    />
                                                  </>
                                                ) : (
                                                  <Icon
                                                    name="minus"
                                                    size="huge"
                                                    style={{ color: "#444", margin: 5 }}
                                                  />
                                                )}
                                              </div>
                                            </GridColumn>
                                            <GridColumn width="5">
                                              <div className="slot">
                                                {box_status.boxes.find(
                                                  (box) =>
                                                    box.box_id === +selectedModuleNumber
                                                ).slots[2].state !== "Empty" ? (
                                                  <>
                                                    <img
                                                      src={
                                                        iconMapping[
                                                        box_status.boxes.find(
                                                          (box) =>
                                                            box.box_id ===
                                                            +selectedModuleNumber
                                                        ).slots[2].state
                                                        ]
                                                      }
                                                      alt=""
                                                      className="slot-icon"
                                                    />
                                                    <StateOfCharge
                                                      index={2}
                                                      {...{
                                                        selectedModuleNumber,
                                                        box_status,
                                                      }}
                                                    />
                                                  </>
                                                ) : (
                                                  <Icon
                                                    name="minus"
                                                    size="huge"
                                                    style={{ color: "#444", margin: 5 }}
                                                  />
                                                )}
                                              </div>
                                            </GridColumn>
                                            <GridColumn width="5">
                                              <div className="slot">
                                                {box_status.boxes.find(
                                                  (box) =>
                                                    box.box_id === +selectedModuleNumber
                                                ).slots[4].state !== "Empty" ? (
                                                  <>
                                                    <img
                                                      src={
                                                        iconMapping[
                                                        box_status.boxes.find(
                                                          (box) =>
                                                            box.box_id ===
                                                            +selectedModuleNumber
                                                        ).slots[4].state
                                                        ]
                                                      }
                                                      alt=""
                                                      className="slot-icon"
                                                    />
                                                    <StateOfCharge
                                                      index={4}
                                                      {...{
                                                        selectedModuleNumber,
                                                        box_status,
                                                      }}
                                                    />
                                                  </>
                                                ) : (
                                                  <Icon
                                                    name="minus"
                                                    size="huge"
                                                    style={{ color: "#444", margin: 5 }}
                                                  />
                                                )}
                                              </div>
                                            </GridColumn>
                                            <GridColumn width="5">
                                              <div className="slot">
                                                {box_status.boxes.find(
                                                  (box) =>
                                                    box.box_id === +selectedModuleNumber
                                                ).slots[1].state !== "Empty" ? (
                                                  <>
                                                    <img
                                                      src={
                                                        iconMapping[
                                                        box_status.boxes.find(
                                                          (box) =>
                                                            box.box_id ===
                                                            +selectedModuleNumber
                                                        ).slots[1].state
                                                        ]
                                                      }
                                                      alt=""
                                                      className="slot-icon"
                                                    />
                                                    <StateOfCharge
                                                      index={1}
                                                      {...{
                                                        selectedModuleNumber,
                                                        box_status,
                                                      }}
                                                    />
                                                  </>
                                                ) : (
                                                  <Icon
                                                    name="minus"
                                                    size="huge"
                                                    style={{ color: "#444", margin: 5 }}
                                                  />
                                                )}
                                              </div>
                                            </GridColumn>

                                            <GridColumn width="5">
                                              <div className="slot">
                                                {box_status.boxes.find(
                                                  (box) =>
                                                    box.box_id === +selectedModuleNumber
                                                ).slots[3].state !== "Empty" ? (
                                                  <>
                                                    <img
                                                      src={
                                                        iconMapping[
                                                        box_status.boxes.find(
                                                          (box) =>
                                                            box.box_id ===
                                                            +selectedModuleNumber
                                                        ).slots[3].state
                                                        ]
                                                      }
                                                      alt=""
                                                      className="slot-icon"
                                                    />
                                                    <StateOfCharge
                                                      index={3}
                                                      {...{
                                                        selectedModuleNumber,
                                                        box_status,
                                                      }}
                                                    />
                                                  </>
                                                ) : (
                                                  <Icon
                                                    name="minus"
                                                    size="huge"
                                                    style={{ color: "#444", margin: 5 }}
                                                  />
                                                )}
                                              </div>
                                            </GridColumn>
                                            <GridColumn width="5">
                                              <div className="slot">
                                                {box_status.boxes.find(
                                                  (box) =>
                                                    box.box_id === +selectedModuleNumber
                                                ).slots[5].state !== "Empty" ? (
                                                  <>
                                                    <img
                                                      src={
                                                        iconMapping[
                                                        box_status.boxes.find(
                                                          (box) =>
                                                            box.box_id ===
                                                            +selectedModuleNumber
                                                        ).slots[5].state
                                                        ]
                                                      }
                                                      alt=""
                                                      className="slot-icon"
                                                    />
                                                    <StateOfCharge
                                                      index={5}
                                                      {...{
                                                        selectedModuleNumber,
                                                        box_status,
                                                      }}
                                                    />
                                                  </>
                                                ) : (
                                                  <Icon
                                                    name="minus"
                                                    size="huge"
                                                    style={{ color: "#444", margin: 5 }}
                                                  />
                                                )}
                                              </div>
                                            </GridColumn>
                                          </>
                                        )}
                                      </>
                                    )}
                                </Grid>
                              )}
                          </GridColumn>
                        </Grid>
                      }
                      Left={
                        <Grid>
                          {station &&
                            station.modules.map((box, index) => (
                              <ChargeModule
                                key={index}
                                module_number={box.box_number}
                                {...{
                                  opened_doors,
                                  setSelectedModuleNumber,
                                  selectedModuleNumber,
                                  module_count: station.modules.length,
                                }}
                              />
                            ))}
                        </Grid>
                      }
                    />
                  )}
                </Segment>
              )}
            </>
        )
      }
    </Grid>
  );
};

