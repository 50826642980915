import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { openDoors, validateQrCode } from "../graphql/mutations";
import { navigate } from "@reach/router";
import { toast } from "react-toastify";
import React from "react";
import NotificationContent from "../components/NotificationContent";

export const openModuleDoor = createAsyncThunk(
  "openModuleDoor",
  async (payload, { dispatch }) => {
    try {
      let qrCodeValidationResponse = await API.graphql(
        graphqlOperation(validateQrCode, { code: payload.validation_hash_key })
      );
      let unique_validation_hash_key =
        qrCodeValidationResponse.data.validate_qr_code.data
          .unique_validation_hash_key;
      payload["token"] = unique_validation_hash_key;
      delete payload["validation_hash_key"];
      let { data } = await API.graphql(graphqlOperation(openDoors, payload));
      const response = data.openDoors.data;
      const { code, message } = data.openDoors;

      if (code === "200") {
        navigate("/swap/" + unique_validation_hash_key);
        return response;
      } else {
        toast.error(
          <NotificationContent iconName={"close"} message={message} />,
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false
          }
        );
      }
    } catch (error) {
      toast.error(
        <NotificationContent iconName={"close"} message={error.message} />,
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false
        }
      );
    }
  }
);

const commandSlice = createSlice({
  name: "commands",
  initialState: {
    error: "",
    openDoorIsLoading: false
  },
  reducers: {},
  extraReducers: {
    [openModuleDoor.pending]: (state, actions) => {
      state.openDoorIsLoading = true;
    },
    [openModuleDoor.fulfilled]: (state, actions) => {
      state.openDoorIsLoading = false;
    },
    [openModuleDoor.rejected]: (state, actions) => {
      state.error = actions;
      state.openDoorIsLoading = false;
    }
  }
});

export default commandSlice.reducer;
