import React, { useMemo, Fragment } from "react";
import {
  Grid,
  GridColumn,
} from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, navigate } from "@reach/router";
import { parse } from "query-string";
import { BeatLoader } from "react-spinners";
import LeftBottomButton from "../LeftBottomButton";
import HeaderLayout from "../HeaderLayout";

import { openModuleDoor } from "../../store/commandSlice";
import BatteryItem from "../../shared/components/BatteryItem"

import useTimeout from "../../shared/hooks/useTimeout"
import LogoutAlert from "../LogoutAlert"

export default ({
  handleTouchScreenEvent,
  is_admin,
  t,
  station
}) => {
  const [showLogoutAlert, setShowLogoutAlert] = useTimeout()
  const { selectedSharingpoint } = useSelector(state => state.sharingpoints);
  const { openDoorIsLoading } = useSelector(state => state.command);
  const location = useLocation();
  const searchParams = parse(location.search);

  const dispatch = useDispatch();
  let selectedBatteryType = useMemo(() => {
    if (!searchParams) return;
    return searchParams.selectedBatteryType;
  }, [searchParams]);



  const supported_batteries = useMemo(() => {
    if (!selectedSharingpoint) return;

    if (!selectedBatteryType) {
      navigate(
        `/open-doors?selectedBatteryType=${selectedSharingpoint.supported_batteries[0]
        }`
      );
    }
    return selectedSharingpoint.supported_batteries;
  }, [selectedSharingpoint]);

  const onOpenDoor = (batteryType) => {
    navigate(`/open-doors?selectedBatteryType=${batteryType}`);
    if (!selectedSharingpoint.ongoing_swap && !openDoorIsLoading) {
      dispatch(
        openModuleDoor({
          sharing_point_name: selectedSharingpoint.sharing_point_name,
          battery_type: batteryType.replaceAll("_", " "),
          validation_hash_key: selectedSharingpoint.validation_hash_key
        })
      );
    }
  }

  return (
    <>
      <Grid
        textAlign="center"
        columns="equal"
        style={{
          margin: "auto",
          width: "90%",
          position: "fixed",
          top: 200,
          zIndex: 50,
          left: 70
        }}
        onClick={() => handleTouchScreenEvent()}
      >
        <HeaderLayout title={t("Open modules")} station={station} />
        {is_admin ? <LeftBottomButton navigateTo={"/admin-logged-in"} /> : <LeftBottomButton activateLogout={true} />}

        {!selectedSharingpoint && (
          <GridColumn textAlign="center" style={{ marginTop: 300, zIndex: 50 }}>
            <BeatLoader
              color={"#ff5a00"}
              loading={true}
              height={10}
              width={30}
            />
          </GridColumn>
        )}
        {showLogoutAlert ? <LogoutAlert setShowLogoutAlert={setShowLogoutAlert} /> : null}

        {!showLogoutAlert && selectedSharingpoint && (
          <>
            {supported_batteries.map(item => (
              <Fragment key={item} >
                {
                  <GridColumn
                    key={item}
                    verticalAlign="bottom"
                    textAlign="center"
                    style={{ marginTop: 50, maxWidth: "500px"}}
                  >
                    <BatteryItem
                      {...{
                        selectedBatteryType,
                        is_admin,
                        dispatch,
                        t
                      }}
                      key={item}
                      batteryType={item}
                      sharing_point_name={
                        selectedSharingpoint.sharing_point_name
                      }
                      allowedToForceSwap={
                        selectedSharingpoint.force_to_swap_allowed
                      }
                      handleClickOnBattery={onOpenDoor}
                    />
                  </GridColumn>
                }
              </Fragment>
            ))}
          </>
        )}
      </Grid>
    </>
  );
};