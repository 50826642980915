import React from "react";
import { Header, Label } from "semantic-ui-react";
import leftIcon from "../assets/images/left-icon.png";
import useInternetOnlineStatus from "../shared/hooks/useInternetOnlineStatus";

const HeaderLayout = ({ title, station }) => {
  const { internetAvailable } = useInternetOnlineStatus();
  const { sp_serial_number, is_online, is_swap_ongoing, validation_hash_key } =
    station ? station : {};
  return (
    <>
      <img
        alt=""
        src={leftIcon}
        style={{
          position: "fixed",
          top: 40,
          left: 40,
          zIndex: 20,
          height: "auto"
        }}
      />

      {title && <div
        style={{
          position: "fixed",
          top: 0,
          left: 400,
          backgroundColor: "#ff5a00",
          width: 400,
          height: 200,
          borderBottomLeftRadius: 200,
          borderBottomRightRadius: 200,
          textAlign: "center",
          verticalAlign: "middle",
          zIndex: 2
        }}
      >
        <Header style={{ color: "white", marginTop: 30, fontSize: 40 }} as="h1">
          {title}
        </Header>
      </div>}

      {/* <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "#F4F4F4",
          width: 950,
          height: 600,
          borderBottomRightRadius: "100%",
          // borderBottomRightRadius: 550,
          zIndex: 1
        }}
      ></div> */}
      <span
        style={{
          fontSize: "30px",
          position: "fixed",
          top: 40,
          right: 40,
          zIndex: 70
        }}
      >

        {sp_serial_number && <Label
          content={sp_serial_number}
          circular
          size="massive"
          style={{ backgroundColor: "#ff5a00", color: "white" }}
        />}
        {!internetAvailable ? (
          <Label
            content={"No Internet connection"}
            size="huge"
            circular
            style={{
              backgroundColor: "#444",
              color: "white",

              marginRight: 10,
            }}
          />) : (
          <Label
            content={is_online == "true" ? "Online" : "Offline"}
            className="is-online"
            size="huge"
            circular
            style={{
              backgroundColor: "#ff5a00",
              color: "white",
              marginRight: 10,
            }}
          />
        )}

      </span>
    </>
  );
};

export default HeaderLayout;
