import React from "react";
import { Icon, Loader } from "semantic-ui-react";
import { navigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/loginSlice";
import { unselectSharingpoint } from "../store/sharingpointSlice";

const LeftBottomButton = ({ navigateTo, activateLogout }) => {
  const dispatch = useDispatch();
  const { isLoadingLogout } = useSelector(state => state.login);
  const onLogout = () => {
    dispatch(logout())
    dispatch(unselectSharingpoint())
  }

  return (
    <div
      onClick={() =>
        activateLogout ? onLogout() : navigate(navigateTo)
      }
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        backgroundColor: "#444",
        borderTopRightRadius: "100%",
        height: 130,
        width: 130,
        textAlign: "center",
        verticalAlign: "middle"
      }}
    >
      {!isLoadingLogout ? (
        <Icon
          name={"arrow alternate circle left"}
          style={{ color: "white", marginTop: 40, marginRight: 20 }}
          size="huge"
        />
     
      ) : (
        <Loader
          inline
          active
          inverted
          size="huge"
          style={{ color: "white", marginTop: 40, marginRight: 20 }}
        />
      )}
    </div>
  );
};

export default LeftBottomButton;
