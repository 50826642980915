import React from 'react'
import { Button, Header, Icon, Segment } from 'semantic-ui-react'
import SwapMessage from './SwapMessage'

export default function LogoutAlert({ setShowLogoutAlert }) {
  return (
    <Segment
      style={{
        minHeight: 500,
        borderRadius: 50,
        backgroundColor: "#ff5a00",
        position: "fixed",
        top: 120,
        left: 100,
        zIndex: 40,
        width: 1150
      }}
    >
      <SwapMessage
        Left={
          <Icon
            name="clock outline"
            style={{ color: "#444" }}
            size="massive"
          />
        }
        Right={
          <>
            <Header
              as="h1"
              style={{
                fontColor: "#444",
                color: "#444",
                margin: "auto",
                marginTop: 200
              }}
            >
              The Station will soon logout due to inactivity
            </Header>
            <Button
              circular
              content="Cancel"
              style={{ backgroundColor: "#ff5a00", color: "white" }}
              size="massive"
              onClick={() => setShowLogoutAlert(false)}
            />
          </>
        }
      />
    </Segment>
  )
}
