import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import React from "react";
import awsconfig from "../aws-exports/aws-exports-rel";

import Amplify from "aws-amplify";
import { toast } from "react-toastify";
import NotificationContent from "../components/NotificationContent";
import { navigate } from "@reach/router";

Amplify.configure(awsconfig);

export const login = createAsyncThunk(
  "login",
  async ({ username, password, name }, { dispatch }) => {
    try {
      const response = await Auth.signIn({
        username: username,
        password: password,
        clientId: awsconfig.aws_user_pools_web_client_id,
      });

      let { email } = response.attributes;
      let uname = response.username;
      return {
        username: uname,
        email,
        name,
      };
    } catch (error) {
      console.log(error);
      toast.error(
        <NotificationContent iconName={"close"} message={error.message} />,
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        }
      );
      throw error;
    }
  }
);

export const checkSession = createAsyncThunk(
  "checkSession",
  async (_, { dispatch }) => {
    try {
      const { idToken } = await Auth.currentSession();
      return idToken.payload;
    } catch (error) {
      throw error;
    }
  }
);

export const logout = createAsyncThunk("logout", async () => {
  try {
    await Auth.signOut();
    navigate("/");
    return;
  } catch (error) {
    throw error;
  }
});

export const loginSuccess = createAsyncThunk(
  "loginSuccess",
  async ({ username, name, access_token }, { dispatch }) => {
    try {
      return {
        username,
        name,
        access_token,
      };
    } catch (error) {
      console.log(error);
      toast.error(
        <NotificationContent iconName={"close"} message={error.message} />,
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        }
      );
      throw error;
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoggedIn: false,
    username: "",
    userEmail: "",
    name: "",
    userGroup: "",
    isLoading: false,
    isLoadingLogout: false,
    error: "",
  },
  extraReducers: {
    [loginSuccess.fulfilled]: (state, actions) => {
      state.username = actions.payload.username;
      localStorage.setItem("swobbee_ipc_user_name", actions.payload.name);
      state.userEmail = actions.payload.username;
      state.userGroup = actions.payload.userGroup;
      state.token = actions.payload.access_token;
      state.isLoading = false;
    },
    [loginSuccess.rejected]: (state, actions) => {
      state.isLoading = false;
      state.error = "incorect username or password.";
    },
    [loginSuccess.pending]: (state, actions) => {
      state.isLoading = true;
    },
    [checkSession.fulfilled]: (state, actions) => {
      state.isLoggedIn = true;

      state.username = actions.payload.name;
      state.userEmail = actions.payload.email;
    },
    [checkSession.rejected]: (state, actions) => {
      state.isLoggedIn = false;
      state.error = actions;
    },
    [logout.fulfilled]: (state) => {
      state.isLoggedIn = false;
      localStorage.removeItem("last_touch_event_at");
      localStorage.removeItem("is_admin");
      state.isLoadingLogout = false;
    },
    [logout.pending]: (state, actions) => {
      state.isLoadingLogout = true;
    },
    [logout.rejected]: (state, actions) => {
      state.error = actions.error;
      state.isLoadingLogout = false;
    },
  },
});

export default loginSlice.reducer;
