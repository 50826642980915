import React from 'react'
import HeaderLayout from '../HeaderLayout'
import LeftBottomButton from '../LeftBottomButton'
import PublicInfoDisplay from './PublicInfoDisplay'


export default ({ handleTouchScreenEvent, station}) => {


  return (
    <div
      onClick={() => handleTouchScreenEvent()}
    >
      <HeaderLayout {...{ station }} />
      <LeftBottomButton navigateTo={"/"} />
      <PublicInfoDisplay {...{ station }} />
    </div>
  )
}