/** helper function to corect attribute names
 * @params {Array} sharingPoints list of sharingpoints
 * @return {Array} with simplified sharingPoints
 */

const parseSharingPoints = sharingPoints => {
  let ar = sharingPoints.map(sp => parseSharingPoint(sp));
  return ar;
};

/** helper function as above
 *   @params {Object} sp - sharingpoint
 *   @return {Object} simpler property names object
 * */
const parseSharingPoint = sp => {
  return {
    name: sp.sharing_point_name,
    slug: sp.sharing_point_name_slug,
    street: sp.sharing_point_location_address_street,
    streetNumber: sp.sharing_point_location_address_street_number,
    city: sp.sharing_point_location_address_city,
    zipcode: sp.sharing_point_location_address_zipcode,
    lng: sp.sharing_point_location_gps_long,
    lat: sp.sharing_point_location_gps_lat,
    state: sp.sharing_point_status
  };
};

/**
 * helper function checks if device is mobile
 */
const isMobileDevice = () => {
  let isMobile =
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1;
  return isMobile;
};
/**
 * get the distance between 2 Points
 * @param {Array} pt1 of type [lat, lng]
 * @param {Array} pt2  of type [lat, lng]
 * @param {String} unit
 * @return {Float} distance in KM
 */
const getDistance = (pt1, pt2, unit = "K") => {
  //if(!pt1 || !pt2) return
  if (!pt1 || !pt2) return;
  if (pt1[0] === pt2[0] && pt1[1] === pt2[1]) {
    return 0;
  } else {
    var radlat1 = (Math.PI * pt1[0]) / 180;
    var radlat2 = (Math.PI * pt2[0]) / 180;
    var theta = pt1[1] - pt2[1];
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist.toFixed(3);
  }
};

function persistStorage(thenFunc) {
  thenFunc =
    thenFunc ||
    function(success) {
      if (!success) console.error("Failed to request persistant storage!");
    };
  if (
    typeof navigator === "object" &&
    typeof navigator.storage === "object" &&
    typeof navigator.storage.persist === "function"
  ) {
    navigator.storage
      .persist()
      .then(thenFunc.bind(0, true), thenFunc.bind(0, false));
  } else {
    thenFunc(false);
  }
}

export {
  isMobileDevice,
  parseSharingPoints,
  parseSharingPoint,
  getDistance,
  persistStorage
};
