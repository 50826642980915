import React from "react";
import packagejs from "../../package.json";

export default () => {
  return (
    <>
      <div
       id="stage"
      >
        {process.env.REACT_APP_MODE}
      </div>
      <div color="black" size="large" id="vrsn">
        v{packagejs.version}
      </div>
    </>
  );
};
