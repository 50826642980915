import React, { useMemo, Fragment } from "react";
import {
  Grid,
  GridColumn,
} from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, navigate } from "@reach/router";
import { parse } from "query-string";
import { BeatLoader } from "react-spinners";
import LeftBottomButton from "../LeftBottomButton";
import HeaderLayout from "../HeaderLayout";

import BatteryItem from "../../shared/components/BatteryItem"
import useTimeout from "../../shared/hooks/useTimeout"
import LogoutAlert from "../LogoutAlert"


export default ({
  handleTouchScreenEvent,
  is_admin,
  t,
  station
}) => {
  const [showLogoutAlert, setShowLogoutAlert] = useTimeout()
  const { selectedSharingpoint } = useSelector(state => state.sharingpoints);
  const location = useLocation();
  const searchParams = parse(location.search);

  const dispatch = useDispatch();
  let selectedBatteryType = useMemo(() => {
    if (!searchParams) return;
    return searchParams.selectedBatteryType;
  }, [searchParams]);


  const sp_bat_ready_info = useMemo(() => {
    if (!selectedSharingpoint) return;
    let sp_bat_ready = JSON.parse(selectedSharingpoint.sp_bat_ready_info);
    Object.keys(sp_bat_ready).forEach((key) => {
      !sp_bat_ready[key] && delete sp_bat_ready[key];
    });
    return sp_bat_ready;
  }, [selectedSharingpoint]);

  const sp_bat_inside = useMemo(() => {
    if (!selectedSharingpoint) return;
    let sp_bat_inside_info = JSON.parse(
      selectedSharingpoint.sp_bat_inside_info
    );
    Object.keys(sp_bat_inside_info).forEach((key) => {
      !sp_bat_inside_info[key] && delete sp_bat_inside_info[key];
    });
    return sp_bat_inside_info;
  }, [selectedSharingpoint]);

  const handleClickOnBattery = (batteryType) => {
    // console.log(`${batteryType} Clicked`)
    navigate(
      `/batteries?selectedBatteryType=${batteryType}`
    );
  }

  return (
    <>
      <Grid
        textAlign="center"
        columns="equal"
        style={{
          margin: "auto",
          width: "90%",
          position: "fixed",
          top: 200,
          zIndex: 50,
          left: 70
        }}
        onClick={() => handleTouchScreenEvent()}
      >
        <HeaderLayout title={t("Choose battery")} station={station} />
        {is_admin ? <LeftBottomButton navigateTo={"/admin-logged-in"} /> : <LeftBottomButton activateLogout={true} />}

        {!selectedSharingpoint && (
          <GridColumn textAlign="center" style={{ marginTop: 300, zIndex: 50 }}>
            <BeatLoader
              color={"#ff5a00"}
              loading={true}
              height={10}
              width={30}
            />
          </GridColumn>
        )}
        {showLogoutAlert ? <LogoutAlert setShowLogoutAlert={setShowLogoutAlert} /> : null}

        {!showLogoutAlert && selectedSharingpoint && (
          <>
            {Object.keys(
              selectedSharingpoint.force_to_swap_allowed
                ? sp_bat_inside
                : sp_bat_ready_info
            ).length === 0 && (
                <span
                  style={{
                    zIndex: 9999,
                    fontSize: 35,
                    color: "#444",
                    position: "fixed",
                    top: 400,
                  }}
                >
                  No batteries available
                </span>
              )}
            {Object.keys(
              selectedSharingpoint.force_to_swap_allowed
                ? sp_bat_inside
                : sp_bat_ready_info
            )
              .sort()
              .map((key) => (
                <Fragment key={key}>
                  {(selectedSharingpoint.force_to_swap_allowed
                    ? sp_bat_inside[key] !== 0
                    : sp_bat_ready_info[key] !== 0) && (
                      <GridColumn
                        key={key}
                        verticalAlign="bottom"
                        textAlign="center"
                        style={{ marginTop: 50, maxWidth: "500px" }}
                      >
                        <BatteryItem
                          {...{
                            selectedBatteryType,
                            is_admin,
                            dispatch,
                            handleClickOnBattery,
                            t
                          }}
                          key={key}
                          batteryType={key}
                          sharing_point_name={
                            selectedSharingpoint.sharing_point_name
                          }
                          allowedToForceSwap={
                            selectedSharingpoint.force_to_swap_allowed
                          }
                        />
                      </GridColumn>
                    )}
                </Fragment>
              ))}
          </>
        )}
      </Grid>
    </>
  );
};
