import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import {
  Grid,
  GridColumn,
  Select,
  Segment,
  Form,
  FormField,
  Button,
  Label,
  Icon,
  Message,
  Loader,
} from "semantic-ui-react";
import axios from "axios";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";
import NotificationContent from "../NotificationContent";
import config from "../../aws-exports/aws-exports-rel";
import HeaderLayout from "../HeaderLayout";

export default ({ station }) => {
  const [stationIds, setStationIds] = useState();
  const [stationId, setStationId] = useState();
  const [ipv4, setIpv4] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();
  const cookies = new Cookies();

  const fetStationIds = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        config.FLASK_REST_API + "/sharing_point_ids",
        {
          headers: {
            client_id: config.client_id,
            access_token: localStorage.getItem(
              `CognitoIdentityServiceProvider.${
                config.aws_user_pools_web_client_id
              }.${localStorage.getItem(
                `CognitoIdentityServiceProvider.${config.aws_user_pools_web_client_id}.LastAuthUser`
              )}.accessToken`
            ),
          },
        }
      );
      setStationIds(data.data);
    } catch (error) {
      setError(
        error.response.message
          ? error.response.message
          : "Internal server error"
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetStationIds();
    getIPv4();
    return () => {
      setStationIds(null);
      setStationId(null);
      setError(null);
    };
  }, []);

  const getIPv4 = async () => {
    const { data } = await axios.get("https://geolocation-db.com/json/");
    setIpv4(data);
    return data;
  };

  const unlink = async () => {
    await axios.post(
      config.FLASK_REST_API +
        `/sharingpoints/${station.sp_serial_number}/settings/unlink`,
      {}
    );

    localStorage.removeItem("my_sp_serial_number_is");
    fetStationIds();
  };

  const postSettings = async () => {
    try {
      const { data } = await axios.post(
        `${config.FLASK_REST_API}/ipc-app/settings`,
        {
          hardware_id: cookies.get("hardware_id"),
          stationId,
        }
      );
      const { hardware_id } = data.data;

      cookies.set("hardware_id", hardware_id, { path: "/" });

      localStorage.setItem("my_sp_serial_number_is", stationId);
      window.location = "/";
      // navigate("/")
      toast.error(
        <NotificationContent
          iconName={"checkmark"}
          message={"Successfuly saved sp serial number"}
        />,
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        }
      );
    } catch (error) {
      toast.error(
        <NotificationContent
          iconName={"close"}
          message={error.response.data.message}
        />,
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        }
      );
    }
  };

  return (
    <Grid>
      <HeaderLayout title="Settings" station={station}/>

      <Grid
        as={Segment}
        columns="1"
        style={{
          width: 1150,
          borderRadius: 20,
          position: "fixed",
          top: 150,
          left: 70,
          zIndex: 100,
        }}
      >
        {error && (
          <GridColumn>
            <Message size="massive" negative content={error} />
          </GridColumn>
        )}

        {isLoading && (
          <GridColumn>
            <Loader active inline size="big" />
          </GridColumn>
        )}

        {stationIds && (
          <GridColumn>
            <Form size="huge">
              <FormField>
                <label>sp serial number is linked to hardware id</label>
                <Label
                  color="orange"
                  size="huge"
                  circular
                  content={station ? station.sp_serial_number : "-"}
                />
                <Icon name="linkify" />

                {station && station.hardware_id ? (
                  <>
                    <Label
                      size="huge"
                      circular
                      content={station.hardware_id}
                      color="orange"
                    />
                    <Button
                      basic
                      content="unlink"
                      icon="unlink"
                      circular
                      onClick={() => unlink()}
                    />
                  </>
                ) : (
                  "None"
                )}
              </FormField>
              <FormField>
                <label>Hardware Id</label>
                {cookies.get("hardware_id")}
              </FormField>
              <FormField>
                <label>IPv4</label>
                {ipv4 && ipv4.IPv4}
              </FormField>
              {(!station || !station.hardware_id) && (
                <FormField>
                  <label>Sp serial number</label>
                  {stationIds && (
                    <Select
                      search
                      clearable
                      fluid
                      style={{ borderRadius: "20px" }}
                      onChange={(_, { value }) => {
                        setStationId(value);
                      }}
                      defaultValue={stationId}
                      value={stationId ? stationId : ""}
                      compact
                      options={stationIds}
                      placeholder={"sp serial number..."}
                    />
                  )}
                </FormField>  
              )}

              <FormField>
                <Button
                  content="Save"
                  floated="right"
                  size="massive"
                  circular
                  style={{
                    backgroundColor: "#ff5a00",
                    color: "white",
                  }}
                  onClick={() => {
                    postSettings();
                  }}
                  disabled={!stationId}
                />
                <Button
                  content="Cancel"
                  floated="right"
                  basic
                  size="massive"
                  circular
                  onClick={() => {
                    navigate("/admin-logged-in");
                  }}
                />
              </FormField>
            </Form>
          </GridColumn>
        )}
      </Grid>
    </Grid>
  );
};
