import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  Grid,
  GridColumn,
  Button,
  Label,
} from "semantic-ui-react";
import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, navigate } from "@reach/router";
import { parse } from "query-string";
import { BeatLoader } from "react-spinners";
import { upperFirst } from "lodash";
import BatteryImage from "../BatteryImage";
import LeftBottomButton from "../LeftBottomButton";
import { createOrder } from "../../store/swapOrderSlice";
import HeaderLayout from "../HeaderLayout";
import { useLongPress, LongPressDetectEvents } from "use-long-press";
import { toast } from "react-toastify";
import NotificationContent from "../NotificationContent";


import useTimeout from "../../shared/hooks/useTimeout"
import LogoutAlert from "../LogoutAlert"

export default ({
  handleTouchScreenEvent,
  is_admin,
  t,
  station
}) => {
  const { selectedSharingpoint } = useSelector((state) => state.sharingpoints);
  const location = useLocation();
  const searchParams = parse(location.search);
  const [showLogoutAlert, setShowLogoutAlert] = useTimeout()

  const dispatch = useDispatch();

  let selectedBatteryType = useMemo(() => {
    if (!searchParams) return;
    return searchParams.selectedBatteryType;
  }, [searchParams]);

  const sp_bat_ready_info = useMemo(() => {
    if (!selectedSharingpoint) return;
    let sp_bat_ready = JSON.parse(selectedSharingpoint.sp_bat_ready_info);
    Object.keys(sp_bat_ready).forEach((key) => {
      !sp_bat_ready[key] && delete sp_bat_ready[key];
    });
    if (!selectedBatteryType) {
      navigate(
        `/batteries?selectedBatteryType=${Object.keys(sp_bat_ready).sort()[0]}`
      );
    }
    return sp_bat_ready;
  }, [selectedSharingpoint]);

  const sp_bat_inside = useMemo(() => {
    if (!selectedSharingpoint) return;
    let sp_bat_inside_info = JSON.parse(
      selectedSharingpoint.sp_bat_inside_info
    );
    Object.keys(sp_bat_inside_info).forEach((key) => {
      !sp_bat_inside_info[key] && delete sp_bat_inside_info[key];
    });
    if (!selectedBatteryType) {
      navigate(
        `/batteries?selectedBatteryType=${Object.keys(sp_bat_inside_info).sort()[0]
        }`
      );
    }
    return sp_bat_inside_info;
  }, [selectedSharingpoint]);

  return (
    <>
      <Grid
        textAlign="center"
        columns="1"
        style={{
          margin: "auto",
          width: "90%",
          position: "fixed",
          top: 120,
          zIndex: 50,
          left: 70,
        }}
        onClick={() => handleTouchScreenEvent()}
      >
        <HeaderLayout title={"Choose quantity"} station={station} />
        <LeftBottomButton navigateTo={"/single-swap"} activateLogout={false} />

        {!selectedSharingpoint && (
          <GridColumn textAlign="center" style={{ marginTop: 300, zIndex: 50 }}>
            <BeatLoader
              color={"#ff5a00"}
              loading={true}
              height={10}
              width={30}
            />
          </GridColumn>
        )}
        {showLogoutAlert ? <LogoutAlert setShowLogoutAlert={setShowLogoutAlert} /> : null}

        {!showLogoutAlert && selectedSharingpoint && (
          <>
            {Object.keys(
              selectedSharingpoint.force_to_swap_allowed
                ? sp_bat_inside
                : sp_bat_ready_info
            ).length === 0 && (
                <span
                  style={{
                    zIndex: 9999,
                    fontSize: 35,
                    color: "#444",
                    position: "fixed",
                    top: 400,
                  }}
                >
                  No batteries available
                </span>
              )}
            {(selectedSharingpoint.force_to_swap_allowed
              ? sp_bat_inside[selectedBatteryType] !== 0
              : sp_bat_ready_info[selectedBatteryType] !== 0) && (
                <BatteryItem
                  {...{
                    selectedBatteryType,
                    is_admin,
                    dispatch,
                    t
                  }}
                  key={selectedBatteryType}
                  batteryType={selectedBatteryType}
                  batteryReady={
                    sp_bat_ready_info[selectedBatteryType] ? sp_bat_ready_info[selectedBatteryType] : 0
                  }
                  battery_inside={
                    sp_bat_inside[selectedBatteryType] ? sp_bat_inside[selectedBatteryType] : 0
                  }
                  sharing_point_name={
                    selectedSharingpoint.sharing_point_name
                  }
                  allowedToForceSwap={
                    selectedSharingpoint.force_to_swap_allowed
                  }
                />
              )}
          </>
        )}
      </Grid>
    </>
  );
};

const BatteryItem = ({
  batteryReady,
  batteryType,
  selectedBatteryType,
  is_admin,
  dispatch,
  sharing_point_name,
  battery_inside,
  allowedToForceSwap,
  greenpackImg,
  kumpanImg,
  torrotImg,
  okaiAImg,
  segwayImg,
  aesImg,
  okaiBImg,
  t
}) => {
  const [batteryCount, setBatteryCount] = useState(1);
  const { selectedSharingpoint } = useSelector((state) => state.sharingpoints);
  const { isLoading } = useSelector((state) => state.swapOrder);

  const callbackPlusButton = useCallback(() => {
    setBatteryCount(batteryReady);
  }, []);

  const callbackMinusButton = useCallback(() => {
    setBatteryCount(0);
  }, []);
  const bindPlus = useLongPress(callbackPlusButton, {
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH,
  });

  const bindMinus = useLongPress(callbackMinusButton, {
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH,
  });

  useEffect(() => {
    if (batteryCount > batteryReady && batteryCount - batteryReady === 1) {
      toast.error(
        <NotificationContent
          iconName={"warning"}
          message={`By requesting more than
          ${batteryReady} ${upperFirst(
            batteryType.replaceAll("_", " ").substr(0, 10)
          )} batteries you'll get batteries with state of charge bellow 85%.`}
        />,
        {
          position: "bottom-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        }
      );
    }
  }, [batteryCount]);

  return (
    <Grid
      textAlign="center"
      columns="equal"
      style={{
        marginLeft: "5%",
        borderRadius: 40,
        width: "100%",
        zIndex: 1,
        height: 800,
      }}
      onClick={() => {
        navigate(`/batteries?selectedBatteryType=${batteryType}`);
      }}
    >
      <GridColumn width={10} >
        <div
          style={{ display: "flex", flexDirection: "column", width: "77%", borderTop: "solid 5px", borderRight: "solid 5px", borderLeft: "solid 5px", height: 475, margin: "auto", borderRadius: "60px 60px 0px 0px", backgroundColor: "white" }}>
          {/* Battery image */}
          <div style={{
            marginTop: 80
          }}>
            <BatteryImage
              type={batteryType.replaceAll("_", " ")}
              height="350px"
              width="auto"
              {...{
                greenpackImg,
                kumpanImg,
                torrotImg,
                okaiAImg,
                segwayImg,
                aesImg,
                okaiBImg,
                t
              }}
            />
          </div>

        </div>
        {/* Requested battery number */}
        <div
          width="16"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            icon={{ name: "minus", size: "huge" }}
            style={{
              color: "black",
              backgroundColor: "white",
              width: 150,
              height: 150,
              border: "solid 5px",
            }}
            circular
            disabled={batteryCount <= 0}
            onClick={() => {
              if (batteryCount > 0) setBatteryCount(batteryCount - 1);
            }}
            {...bindMinus}
          />

          <div
            style={{
              height: 50,
              width: 50,
              borderRadius: "150%",
              verticalAlign: "middle",
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: batteryCount > batteryReady ? "red" : "black",
                fontSize: 120,
                fontWeight: "bolder"
              }}
            >
              {batteryCount}
            </span>
          </div>

          <Button
            icon={{ name: "plus", size: "huge" }}
            circular
            disabled={
              allowedToForceSwap
                ? battery_inside === batteryCount
                : batteryCount === batteryReady
            }
            style={{
              color: "black",
              backgroundColor: "white",
              width: 150,
              height: 150,
              border: "solid 5px"
            }}
            onClick={() => {
              setBatteryCount(batteryCount + 1);
            }}
            {...bindPlus}
          />
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "77%", borderRight: "solid 5px", borderLeft: "solid 5px", height: "100%", margin: "auto", backgroundColor: "white" }}>
          {/* ready battery number */}
          <Label
            content={`${batteryReady ? batteryReady : 0} Ready`}
            // size="huge"
            style={{
              // marginTop: "30px",
              padding: 0,
              fontSize: "30px",
              backgroundColor: "white",
            }}
          />
        </div>

      </GridColumn>
      <GridColumn width={6}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", height: "100%", rowGap: "20px" }}>
          {/* Battery label */}
          <div style={{ display: "flex", flexDirection: "column", rowGap: "40px" }}>
            <span
              style={
                { color: "black", fontSize: 30 }
              }

            >Battery Type</span>
            <span
              as="h1"
              style={
                { color: "black", fontSize: 60, fontWeight: "bolder" }
              }
            >
              {upperFirst(batteryType.replaceAll("_", " ")).toUpperCase().split(" ", 2).join(" ")}
            </span>
          </div>

          <Button
            content={t("Swap")}
            circular
            // size="large"
            className="swap-button"
            style={{
              margin: "75px 20px 0px",
              padding: "35px 0px",
              fontSize: 60,
              color: "white",
              backgroundColor: "#ff5a00",
              fontWeight: "bolder"
            }}
            disabled={batteryCount === 0 || selectedSharingpoint.ongoing_swap}
            loading={isLoading}
            onClick={() => {
              dispatch(
                createOrder({
                  batteryType,
                  batteryCount,
                  unique_validation_hash_key:
                    selectedSharingpoint.validation_hash_key,
                  sharing_point_name: selectedSharingpoint.sharing_point_name,
                })
              );
            }}
          />
        </div>

      </GridColumn>
    </Grid>
  );
};
