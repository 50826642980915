/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sub2SwapStateNotification = `subscription Sub2SwapStateNotification(
  $sharing_point_name: String!, 
  $unique_validation_hash_key: String!, 
  $user_name: String!
) {
  sub2SwapStateNotification(
    sharing_point_name: $sharing_point_name
    unique_validation_hash_key: $unique_validation_hash_key
    user_name: $user_name
  ) {
    code
    success
    message
    sharing_point_name
    unique_validation_hash_key
    user_name
    data {
      sharing_point_name
      swap_state
      user_name
      unique_validation_hash_key  
    }
    errors {
      err_message
      data
    }
  }
}
`;

export const onEntryChange = `subscription onEntryChange {
  entryChange {
    id
    source
  }
}
`
