import React, { useState } from "react";
import { Loader } from "semantic-ui-react";

export default ({
  type,
  height,
  width,
  maxHeight,
  float,
}) => {
  const [loaded, setLoaded] = useState(false);
  // const imageUrls = {
  //   greenpack: greenpackImg,
  //   Greenpack: greenpackImg,
  //   kumpan: kumpanImg,
  //   torrot: torrotImg,
  //   "Okai A": okaiAImg,
  //   Tier_dev: okaiAImg,
  //   "Tier dev": okaiAImg,
  //   "Okai B": okaiBImg,
  //   Okai_B: segwayImg,
  //   Okai_A: okaiAImg,
  //   Segway: segwayImg,
  //   "AES eBike Akku 2.0 48V": aesImg,
  // };
  return (
    <>
      <img
        alt=""
        style={{
          float: float,
          margin: "auto",
          height,
          width,
          display: loaded ? "block" : "none",
          padding: 0,
          maxHeight
        }}
        onLoad={() => {
          setLoaded(true);
        }}
        src={`https://swobbee-frontend-assets.s3.eu-central-1.amazonaws.com/${type.toLowerCase()}.png`}
      // src={`https://swobbee-frontend-assets-dev.s3.eu-central-1.amazonaws.com/${type.toLowerCase()}.png`}
      />

      {!loaded ? (
        <div style={{ margin: "auto", height, width }}>
          <Loader active inline size="massive" />
        </div>
      ) : null}
    </>
  );
};
