import React from 'react'
import slotIsPresent from '../../assets/images/slots/slot_is_present.png'
import slotIsReady from '../../assets/images/slots/slot_is_ready.png'
import thumbnailCharging from '../../assets/images/slots/thumbnail_CHARGING.png'
import thumbnailEmpty from '../../assets/images/slots/thumbnail_EMPTY.png'
import thumbnailError from '../../assets/images/slots/ERROR.png'

export default function BattCard({ slotNumber, slot_state, is_charging, soc_value, index, displayAsSmall }) {
  var isSlotEmpty = false;
  var isBattReady = false;
  var isBattError = false;
  if (slot_state === "Empty") { isSlotEmpty = true }
  if (slot_state === "Ready") { isBattReady = true }
  if (slot_state === "Error") { isBattError = true }
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", borderTop: index > 0 ? "solid" : "" }} >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ position: "relative", display: "inline-block" }}>
          {isSlotEmpty ? (
            <>
              <img src={slotIsPresent} alt="" style={{ objectFit: "contain", height: displayAsSmall ? "90px" : "120px" }} />
              <img src={thumbnailEmpty} alt="" style={{
                objectFit: "contain", height: displayAsSmall ? "50px" : "75px",
                position: "absolute", top: "48%", left: "50%", transform: "translate(-50%, -50%)",
              }} />
            </>
          ) : (
            <>
              {isBattReady ? (
                <>
                  <img src={slotIsReady} alt="" style={{ objectFit: "contain", height: displayAsSmall ? "90px" : "120px" }} />
                  <div style={{
                    position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
                    fontSize: "35px", color: "white", fontWeight: "bolder"
                  }}>
                    {soc_value ? soc_value + "%" : null}
                  </div>
                </>
              ) : (
                <>
                  <img src={slotIsPresent} alt="" style={{ objectFit: "contain", height: displayAsSmall ? "90px" : "120px" }} />
                  <div style={{
                    position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
                    fontSize: "35px", fontWeight: "bolder"
                  }}>
                    {soc_value ? soc_value + "%" : null}
                  </div>
                </>
              )}
              {is_charging ? <img src={thumbnailCharging} alt="" style={{ height: "45%", position: "absolute", bottom: "0", right: "0", }} /> : null}
              {isBattError ? <img src={thumbnailError} alt="" style={{ height: "45%", position: "absolute", bottom: "0", right: "0", }} /> : null}
            </>
          )}
        </div>
      </div >

      <div style={{ textAlign: "center", fontSize: "25px", fontWeight: "bolder", textTransform: "capitalize" }}>
        {slotNumber.replace("_", " ")}
      </div>
    </div >
  )
}
