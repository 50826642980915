import React, { useEffect } from "react";
import { Grid, Header, Button, GridColumn } from "semantic-ui-react";
import { navigate } from "@reach/router";
import { useSelector, useDispatch } from "react-redux";
import HeaderLayout from "../HeaderLayout";
import LeftBottomButton from "../LeftBottomButton";
import awsmobile from "../../aws-exports/aws-exports-rel";
import { selectSharingpoint } from "../../store/sharingpointSlice";

import useTimeout from "../../shared/hooks/useTimeout"
import LogoutAlert from "../LogoutAlert"


export default ({
  handleTouchScreenEvent,
  is_allowed_to_fill_in,
  is_admin,
  t,
  station
}) => {
  const { success, isLoading } = useSelector((state) => state.sharingpoints);
  const dispatch = useDispatch();
  const [showLogoutAlert, setShowLogoutAlert] = useTimeout()

  const sharing_point_name = localStorage.getItem("my_sp_serial_number_is");

  useEffect(() => {
    console.log("sharing_point_name", sharing_point_name);
    dispatch(selectSharingpoint({ sharing_point_name }));
    if (!sharing_point_name) return;
    const intervalId = () => {
      setInterval(() => {
        dispatch(selectSharingpoint({ sharing_point_name }));
      }, 150000);
    };
    intervalId();
    return function cleanup() {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Grid onClick={() => handleTouchScreenEvent()} columns="1">
      <HeaderLayout title={"Menu"} station={station}/>
      <LeftBottomButton activateLogout={true} />
      {showLogoutAlert ? <LogoutAlert setShowLogoutAlert={setShowLogoutAlert} /> :
        (
          <>
            <GridColumn
              textAlign="center"
              width="16"
              style={{
                position: "fixed",
                bottom: 350,

                zIndex: 21,
              }}
            >
              <Header style={{ fontSize: 40 }} as="h1">
                {success ? `${t("Welcome")} ${localStorage.getItem(
                  `CognitoIdentityServiceProvider.${awsmobile.aws_user_pools_web_client_id}.LastAuthUser`
                )}` : "Failed to fetch sharing point data"}
              </Header>
            </GridColumn>

            <Button
              style={{
                width: "350px",
                borderRadius: 50,
                backgroundColor: "#ff5a00",
                color: "white",
                fontSize: 40,
                position: "fixed",
                bottom: 110,
                left: 460,
                zIndex: 21,
              }}
              primary
              circular
              size="massive"
              disabled={!is_allowed_to_fill_in}
              content={t("Open Modules")}
              onClick={() => navigate(is_allowed_to_fill_in && "/open-doors")}
            />

            <Button
              style={{
                width: "350px",
                borderRadius: 50,
                backgroundColor: "#ff5a00",
                color: "white",
                fontSize: 40,
                position: "fixed",
                bottom: 110,
                left: 60,
                zIndex: 21,
              }}
              content={t("Single swap")}
              primary
              size="massive"
              disabled={!success}
              onClick={() => navigate("/single-swap")}
            />

            <Button
              style={{
                width: "350px",
                borderRadius: 50,
                backgroundColor: "#ff5a00",
                fontSize: 40,
                position: "fixed",
                bottom: 110,
                left: 860,
                zIndex: 21,
              }}
              disabled={!is_admin || isLoading}
              primary
              circular
              size="massive"
              content={t("Settings")}
              onClick={() => navigate("/settings")}
            />
          </>
        )}
    </Grid>
  );
};
