import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const setError = createAsyncThunk(
  "setErrot",
  async (payload,{dispatch}) => {
    setTimeout(()=>{
      dispatch(reset())
    },5000)
    return payload
  }
);

const errorSlice = createSlice({
  name: "error",
  initialState: {
    message: "",
    statusCode: ""
  },
  reducers: {
    reset: (state,actions) => {
      state.message=""
      state.statusCode=""
    }
  },
  extraReducers: {
    [setError.fulfilled] : (state, actions)=>{
      state.message = actions.payload.message
      state.statusCode=  actions.payload.statusCode
    }
  },
});

export const { reset } = errorSlice.actions;

export default errorSlice.reducer;
