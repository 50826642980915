import React from "react";

import { Icon, Divider } from "semantic-ui-react";
import { upperFirst } from "lodash";

export default ({ iconName, message }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <div style={{ fontSize: 18, fontWeight: "bold", marginTop: 4 }}>
        Notification
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Icon name={iconName} size="big" style={{ color: "#ff5a00" }} />
        <div style={{ verticalAlign: "center", marginLeft: 5 }}>
          {upperFirst(message)}
        </div>
      </div>
    </div>
  );
};
